/*breadcrumb*/
.breadCrumb{
    // display: flex;
    // align-items: center;
    // padding-top: 20px;
    // padding-bottom: 20px;
    // position: absolute;
    // padding-left: 80px;
    // padding-top: 170px;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    position: absolute;
    padding-left: 70px;
    padding-top: 150px;
    li{
        color: #262626 ;
        font-family: 'regular' !important;
        font-size: 16px !important;
        cursor: pointer;
        p{
            font-family: 'regular' !important;
        }
    }
    .bName{
        p{
            font-size: 14px;
            padding-right: 5px;
            margin-right: 5px;
        }
    }
}
/*titleBar*/
.titleBar{
    padding: 25px 0;
    padding-top: 70px;
    h2{
        font-size: 26px;
        font-family: 'bold';
        @media (max-width: 992px) {
            font-size: 22px 
           }
    }
}
/*productCard*/
.pCard {
   
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 0.5px solid #F0F0F0;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    &:hover{
        .viewPrice {
           display: block;
           position: absolute;
           bottom: 0;
           width: 100%;
        }
        
    }
    .pImg{
        height: 150px;
        background: white;
        width: 100%;
        border-radius: 7px;
        figure {
            width: 100%;
            height: 100%;

            img{
                height: 100%;
                object-fit: contain;
                border-radius: 10px 10px 0 0;
            }
        }
        
    }
    .pData{
        padding: 10px 10px 5px 20px;
        box-sizing: border-box;
        figure {
            width: 80px;
            // margin-bottom: 10px;
            position: relative;
        }
        h3{
            font-size: 16px;
            font-family: "bold";
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        ul{
            li{
                color: #4E4E4E;
                font-size: 13px;
                padding-bottom: 6px;
                font-family: 'regular';
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 17px;
                &:nth-child(4){
                    padding-bottom: 13px !important;
                }
                span {
                    font-family: 'bold';
                    margin-right: 4px;
                }
            }
        }
    }
    .viewPrice {
        padding: 10px 0;
        text-align: center;
        font-size: 14px;
        background: transparent linear-gradient(180deg, #6045A0 0%, #47B8E9 100%) 0% 0% no-repeat padding-box;
        color: white;
        overflow: hidden;
        border-radius: 0px 0 5px 5px;
        display: none;

        
    }
    .viewPricenone {
        padding: 10px;
        text-align: center;
        font-size: 14px;
        background: white;
        color: white;
        overflow: hidden;
        border-radius: 0px 0 5px 5px;
    }
}


.load_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    figure{
        width: 100px;
        height: 50px;
    }
}
@media (max-width: 767px) {
    .breadCrumb {
        padding-left: 36px;
    }
}