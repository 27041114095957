@import '../../assets/theme/Variable.scss';

.categriessec {
    padding: 40px 0;
    .category {
        display: flex;
        justify-content: space-between;
        .cleft {
            width: 25%;
            @media (max-width: 992px) {
                width: 35%;
              }
            .clear_all{
                cursor: pointer;
                text-align: right;
                color: #61439F;
            }
            .boldhead{
                span{
                    font-family: 'bold' !important;
                    font-size: 21px !important;
                }
                .subcat{
                    font-family: 'regular' !important;
                }
            }
        }
        .cright {
            width: 73%;
            @media (max-width: 992px) {
                width: 70%;
              }
            .slider-box{
                width:100%;
                .slick-prev {
                    left: -14px !important;
                    z-index: 1;
                }
                .slick-next {
                    right: -1px !important;
                    z-index: 1;
                }
                .sldr-img {
                    width: 23%;
                    width: 96% !important;
                    width: 25%;
                    position: relative;
                    height: 100%;
                    img{
                        height: 100%;
                        border-radius: 5px;
                        background: white;
                        cursor: pointer;
                    }
                }
                p {
                    position: absolute;
                    bottom: 9px;
                    color: white;
                    font-family: 'bold';
                    text-align: center;
                    width: 100%;
                    background: #808080e0;
                    padding: 4px 0;
                }
            }
            .no_data {
                min-height: 180px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                figure{
                    width: 90px;
                    height: 90px;
                    @media (max-width: 767px) {
                        width: 70px;
                        height: 70px;
                    }
                    img{
                        height: 100%;
                    }
                }
              h3{
                font-size: 16px;
                font-family: 'regular';
                color: rgb(128 128 128 / 88%);
              }
            }

            .pmain{
                .tilebar {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h2 {
                        padding: 25px 0;
                        font-size: 26px;
                        font-family: 'bold';
                        @media (max-width: 1100px) {
                            font-size: 21px;
                        }
                    }
                    h4{
                        font-size: 18px;
                        font-family: 'bold';
                        color: #4E4E4E;
                        cursor: pointer;
                        &:hover{
                            color: $secondary;
                        }
                    }
                }
                .product {
                    display: flex;
                    gap: 13px;
                    flex-wrap: wrap;
                    @media  (max-width: 1483px){
                        gap: 12px;   
                    }
                    @media  (max-width: 1370px){
                        gap: 11px;   
                    }
                    @media  (max-width: 1255px){
                        gap: 10px;   
                    }
                    .proCard{
                        width: 24%;
                        @media (max-width: 1200px) {
                            width: 32%;
                        }
                        @media (max-width: 800px) {
                            width: 48%;
                        }
                      
                        .pCard{
                            width: 100%;
                            .rating {
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                    
                }
            }  
        }
    }
    .no_cate{
            min-height: 170px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            figure{
                width: 90px;
                height: 90px;
                margin: auto;
                img{
                    height: 100%;
                }
            }
          h3, h2{
            font-size: 20px;
            font-family: 'bold';
            color: rgb(128 128 128 / 88%);
            text-align: center;
          }
         
    }
}

.banner{
    height: 600px;
    overflow: hidden;
    position: relative;
   @media (max-width: 1060px) {
    height: 550px;
   }
    &::after{
        content: '';
        position: absolute;
        background: transparent linear-gradient(270deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    video{
        overflow: hidden;
        width: 100%;
        position: relative; 
    }
    .bnrImg{
        width: 100%;
        height: 100%;
        display: none;
    }
    .content{
        width: 42%;
        position: absolute;
        top: 0;
        height: 100%;
        left: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        z-index: 1;
        z-index: 1;
        padding-top: 40px;
        padding-bottom: 53px;
        // padding-top: 120px;

        h1{
            font-size: 55px;
            color: #fff;
            font-family: 'extrabold';
            span{
                color : #4eaadf;
            }
            @media (max-width: 1440px) {
                font-size: 44px;
            }

        }
        p{
            padding: 15px 0 30px;
            color: #fff;
            font-size: 19px;
            font-family: 'regular';
            line-height: 27px;
            @media (max-width: 1440px) {
                font-size: 18px;
            }
        }
    }
    .btn{
        width: 28%;
        .btn{
            padding:16px 8px !important;
        }
    }
}
.shopmain{
    background: url("../../../public/static/images/homebg.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 20px;
    padding: 70px;
    margin-bottom: 50px;
    @media (max-width: 1440px) {
        padding: 80px;
    }
    @media (max-width: 1200px) {
        padding: 50px;
    }
    @media (max-width: 1100px) {
       background-position: center;
    }
    @media (max-width: 767px) {
        background: #61439ff7;
     }
    .shopmaiCcontent{
        h2{
            font-size: 55px;
            font-family: 'bold';
            color: $white;
            @media (max-width: 1440px) {
                font-size: 42px;
            }
            @media (max-width: 1100px) {
                font-size: 35px;
            }
        }
        p{
            color: #FFFFFF;
            font-size: 20px;
            padding-top: 14px;
            line-height: 30px;
            font-family: 'regular';
            width: 60%;
            @media (max-width: 1440px) {
                font-size: 20px;
            }
            @media (max-width: 1100px) {
                font-size: 17px;
            }
            @media (max-width: 992px) {
                width: 100%;
            }
        }
        .btn{
            width:16%;
            margin-top: 20px;
            button{
                background: $white !important;
                color: $black !important;
                font-family: 'bold' !important;
                border: 1px inset transparent;
                font-size: 18px !important;
                @media (max-width: 1440px) {
                    font-size: 16px !important;
                }
                @media (max-width: 1440px) {
                    font-size: 15px !important;
                    padding: 4px;
                }
                &:hover{
                    background: $secondary !important;
                    color: $white !important;
                    border: 1px inset $white;
                }
            }
        }
    }
   
}


@media (max-width: 992px) {
    .banner {
        height: auto;
        .content {
            top: 22px;
            width: 80%;
            h1 {
                font-size: 36px;
            }
        }
        &::after {
            height: 100%;
        }
    }
    video {
        min-height: 100%;
    }
    .categriessec {
        .category {
            display: block;
            .cleft {
                width: 100%;
            }
            .cright {
                width: 100%;
            }
        }
    }
}
@media (max-width: 767px) {
    .banner {
        video{
            display: none;
        }
        .bnrImg{
            display: block;
        }
        .content {
            h1 {
                font-size: 28px;
            }
            p {
                font-size: 16px;
            }
        }
        .btn {
            display: none;
        }
    }
    .shopmain {
        .shopmaiCcontent {
            h2 {
                font-size: 30px;
            }
            .btn {
                width: 100px;
            }
        }
    }
}
@media (max-width: 480px) {
    .banner {
        margin-top: 120px;
        .content {
        top: -24px; 
        left: 43px;
            h1 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
                line-height: 17px;
            }
        }
        .btn {
            width: 112px;
            margin-top: -12px;
            display: none;
        }
    }
    .shopmain {
        .shopmaiCcontent {
            text-align: center;
            .btn {
                margin-top: 20px;
                width: 100%;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                width: 100%;
            }
        }
    }
    .categriessec {
        .category {
            .cright {
                .pmain {
                    .product {
                        .proCard {
                            width: 100%;
                        }
                    }
                }
            }
        }

    }
}
@media (max-width: 480px) {
    .categriessec {
        .category {
            .cleft {
                .boldhead {
                    span {
                        font-size: 16px !important;
                    }
                }
            }
        }
    }
}