.notificationWrapper {
  .notificationHead {
    padding-top: 60px;
    h2 {
      font-family: bold;
      font-size: 32px;
      color: #0c1f00;
      text-align: center;
    }
  }
  .body {
    padding-top: 40px;
    padding-bottom: 50px;
    .notificationBody {
      border-radius: 10px;
      display: flex;
      padding: 15px 0px;
      margin-top: 16px;
      box-shadow: 1px 1px 5px 4px #0000001a;
      .content {
        padding-left: 5px;
        h3 {
          font-family: "bold";
          font-size: 20px;
        }
        p {
          padding-top: 8px;
          font-family: "regular";
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .notificationWrapper {
    .notificationHead {
      padding-top: 75px;
      h2 {
        font-size: 24px;
      }
    }
    .body {
      padding-top: 11px;
      .notificationBody {
        .content {
          h3 {
            font-size: 18px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}