.mngaddress-details{
    border:1px solid #B4B4B4;
    font-size: 18px;
    line-height: 30px;
    padding: 12px 14px;
    font-family: 'regular';
    border-radius: 5px;
    margin-bottom: 20px;
}

.mngaddress-div{
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
    .address-data{
        width:39%;
    }
    .address-form.profilemain {
        padding: 0;
    }
    .address-form{
        width:59%
    }
}

.mngaddress-details-p{
color:#4E4E4E;
font-family: 'bold';

}

.mngaddress-details-name{
   font-family: 'bold';
    }

.form-input{
border: 1px solid #B4B4B4;
padding:15px;
border-radius: 5px;
width:46%
    }

    .name-inputs{
        display: flex;
        justify-content: space-between;
    }

  

    .address-input{
        border: 1px solid #B4B4B4;
padding:15px;
border-radius: 5px;
width:100%
    }

@media (max-width: 576px) {
    .manageAddress {
        .addHead {
            display: block;
            .addBtn {
                width: 133px;
                margin-top: 12px;
            }
        }
    }
}