@import "../assets/theme/Variable.scss";
.signRyt {
  .crossbtn {
    display: none;
  }
}
//header
.headermain {
  position: fixed;
  width: 100%;
  z-index: 1000;
  .headertop {
    background: $secondary;
    padding: 10px 0;
    .htmain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .htleft {
        display: flex;
        align-items: center;
        cursor: pointer;
        figure {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 14px;
          padding-left: 10px;
          color: $white;
          font-family: "regular";
          width: 40%;
          padding-top: 5px;
          width: 100%;
          padding-top: 0;
        }
      }
      .htright {
        ul {
          display: flex;
          align-items: center;
          li {
            font-family: "regular";
            cursor: pointer;
            font-size: 16px;
            color: $white;
            font-weight: 500;
            border-right: 1px solid $white;
            padding: 0 10px;
            margin-right: 0;
            &:last-child {
              border-right: 1px solid transparent;
            }
          }
        }
      }
    }
  }
  .headerBtm {
    background: $white;
    padding: 10px 0;
    box-shadow: 0px 2px 26px #0000001a;
    .hbmain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .hBleft {
        display: flex;
        align-items: center;
        width: 60%;
        @media (max-width: 1366px) {
          width: 50%;
        }
        @media (max-width: 991px) {
          width: 80%;
        }
        .hmbrg {
          display: none;
          font-size: 31px;
          padding-right: 10px;
          cursor: pointer;
          color: $secondary;
          @media (max-width: 991px) {
            display: block;
          }
        }
        figure {
          width: 130px;
          height: 66px;
          margin-right: 30px;
          cursor: pointer;
          display: block;
          @media (max-width: 1366px) {
            width: 100px;
            height: 46px;
          }

          // @media (max-width: 991px) {
          //   display: none;
          // }
        }
        .inputField {
          width: 100%;
          max-width: 600px;
          position: relative;
          // @media (max-width: 991px) {
          //   max-width: 700px;
          // }
          .search {
            width: 100%;
          }
          input {
            background: #e6e6e6 !important;
            padding: 10px !important;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          }
          // }
        }
      }
      .hBRight {
        display: flex;
        width: 40%;
        justify-content: flex-end;
        @media (max-width: 1366px) {
          width: 60%;
        }
        // @media (max-width: 991px) {
        //   display: none;
        // }
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            margin-left: 30px;
            cursor: pointer;
            &:hover {
              svg,
              h4 {
                color: $secondary;
              }
            }
            svg {
              font-size: 23px;
              color: $black;
              // &:hover{
              //   color: $secondary;
              // }
            }
            h4 {
              font-size: 14px;
              color: $black;
              font-family: "regular";
              // &:hover{
              //   color: $secondary;
              // }
            }
            h5 {
              font-size: 16px;
              color: #000000;
              font-family: "bold";
            }
            .user_data {
              button {
                display: flex;
                align-items: center;
                background: no-repeat;
                border: none;
                cursor: pointer;
                outline: none;
              }
              .user_img {
                figure {
                  margin-right: 10px;
                  width: 35px;
                  height: 35px;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 40%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//footer
.footermain {
  .topbar {
    background: $secondary;
    padding: 18px;
    @media (max-width: 767px) {
      padding: 18px 0;
    }
    .topmain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tleft {
        display: flex;
        align-items: center;
        width: 30%;
        figure {
          width: 20px;
          height: 20px;
        }
        h3 {
          font-family: bold;
          color: $white;
          padding-left: 10px;
        }
      }
      .tRight {
        width: 50%;
        .inputField {
          background: white;
          position: relative;
          border-radius: 5px;
          input {
            width: 100% !important;
            padding: 12px !important;
          }
          button {
            position: absolute !important;
            right: 3px !important;
            top: 3px !important;
            width: 17% !important;
            background: $secondary !important;
            font-size: 14px !important;
            height: 41px;
          }
        }
      }
    }
  }
  .centerdiv {
    padding: 40px 0;
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 22%;
        // @media (max-width: 992px) {
        //   width: 42%;
        //   margin-bottom: 20px;
        // }
        &:nth-child(1) {
          width: 34%;
        }
        // @media (max-width: 992px) {
        //   width: 54%;
        //   margin-bottom: 20px;
        // }
        h6 {
          font-size: 15px;
          padding-bottom: 15px;
          font-family: "light";
          color: $black;
          cursor: pointer;
          &:hover {
            color: $secondary;
          }
        }
        h2 {
          font-size: 20px;
          padding-bottom: 20px;
          font-family: "bold";
          color: $black;
        }
        figure {
          width: 130px;
          height: 76px;
          margin-bottom: 13px;
          color: $black;
        }
        h3 {
          font-size: 16px;
          font-family: "bold";
        }
        p {
          font-size: 14px;
          font-family: "regular";
          padding-bottom: 20px;
          color: $black;
          width: 70%;
        }
        .social_icons {
          display: flex;
          figure {
            padding-bottom: 20px;
            width: 20px;
            border-radius: 50%;
            height: 20px;
            margin-bottom: 0px;
            padding: 7px;
            line-height: 0;
            background: $secondary;
            margin-right: 14px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              filter: brightness(246.5) saturate(0%);
            }
          }
          svg {
            color: white;
            padding: 5px;
            background: #61439f;
            border-radius: 100%;
            margin-right: 14px;
            cursor: pointer;
          }
        }
        .list {
          ul {
            width: 50%;
            li {
              border-right: 1px solid $black;
              padding-right: 20px;
              cursor: pointer;
              &:nth-child(1) {
                width: auto;
              }
              &:nth-child(3) {
                border-right: 1px solid transparent;
              }
            }
          }
        }
      }
    }
  }
  .bottombar {
    background: $secondary;
    padding: 10px 0;
    h5 {
      font-size: 14px;
      text-align: center;
      color: $white;
      font-family: "light";
    }
  }
}

//login Modal
.loginmodal {
  h2 {
    text-align: center;
  }
  p {
    color: #818181;
    text-align: center;
    font-size: 14px;
    padding-top: 8px;
  }
  h3 {
    font-size: 14px;
    font-family: "regular";
    text-align: center;
    color: #818181;
    padding-top: 60px;
  }
  span {
    color: #61439f;
    cursor: pointer;
  }
  .inputs {
    padding-top: 20px;
    input {
      border: 1px solid #b4b4b4 !important;
      border-radius: 4px !important;
      margin-bottom: 5px;
      padding: 10px !important;
    }
  }

  .fordiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 20px;

    .checkbox {
      display: flex;
      align-items: center;
      span {
        padding: 0;
        padding-right: 7px;
      }
      h5 {
        font-size: 14px;
        color: $black;
      }
    }
    h4 {
      font-size: 15px;
      color: $black;
      cursor: pointer;
      @media (max-width: 480px) {
        margin-top: 10px;
      }
      &:hover {
        color: $secondary;
      }
    }
  }
  .btnddiv {
    button {
      background: linear-gradient(rgb(96, 69, 160) 0%, rgb(71, 184, 233) 100%)
        0% 0% no-repeat padding-box padding-box transparent;
      font-family: "regular";
      font-size: 16px;
      text-transform: capitalize;
      width: 100%;
      padding: 10px 8px;
      color: white;
      font-size: 18px;
      border-radius: 4px;
      outline: none;
      border: none;
      cursor: pointer;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
          rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
      }
    }
  }
}

.signmain {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  .signleft {
    width: 48%;
    background: url(../../public/static/images/factory.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 30px 30px 70px;
    height: auto;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 60px);
    flex-direction: column;
    button {
      position: absolute;
      left: 60px;
      top: 23px;
    }
    h2 {
      font-size: 36px;
      color: white;
    }
    p {
      color: white;
      padding-top: 20px;
      line-height: 26px;
      font-size: 20px;
    }
  }
  .signRyt {
    width: 48%;
    background: white;
    padding: 30px 70px 0 30px;
    min-height: 400px;
    height: calc(100vh - 60px);
    overflow: auto;
    h3 {
      text-align: center;
      font-size: 27px;
      font-family: "bold";
      padding-bottom: 30px;
    }
    p {
      color: #818181;
      text-align: center;
      font-size: 16px;
      font-family: "regular";
      padding-top: 4px;
      padding-bottom: 20px;
    }
    .inputdiv {
      .firstname {
        display: flex;
        justify-content: space-between;

        .fdiv {
          width: 49.4%;
          h4 {
            margin-bottom: 2px;
            font-size: 15px;
            font-family: "regular";
            color: rgba(0, 0, 0, 0.8509803922);
          }
          input {
            border: 0.3px solid #b4b4b4 !important;
            border-radius: 4px !important;
            margin-bottom: 12px;
            padding: 12px !important;
          }
        }
      }
      .phoneInput {
        padding-left: 50px !important;
        width: 100% !important;
      }
      .phoneBtn {
        padding: 10px 1px !important;
        margin-top: -5px !important;
      }
      .errMsg {
        color: red;
        font-size: 14px;
        font-weight: 400;
        font-family: "regular";
        line-height: 1px;
        padding-bottom: 26px;
        text-align: left;
      }
      input {
        border: 0.3px solid #b4b4b4 !important;
        border-radius: 4px !important;
        margin-bottom: 12px;
        padding: 12px !important;
      }
      figure {
        width: 30px;
        height: 30px;
      }
      .btn {
        width: 20%;
        padding-top: 15px;
      }
      .checkbox {
        display: flex;
        align-items: center;
        span {
          padding: 0 !important;
        }
        h5 {
          font-size: 14px;
          padding-left: 7px;
          font-family: "regular";
          color: #818181;
        }
      }
    }
    .btn {
      width: 100% !important;
      padding-top: 15px;
    }
    .bottomline {
      display: flex;
      align-items: center;
      p {
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
        width: 100%;
        font-family: "regular";
        padding-top: 30px;
        span {
          color: $secondary;
          cursor: pointer;
        }
      }
    }
  }
}

//otpmodal

.otpModal {
  .cancelBtn {
    display: flex;
    justify-content: flex-end;
  }

  h2 {
    font-family: "bold";
    font-size: 22px;
    color: #61439f !important;
    text-align: center;
  }
  .otpInfo {
    padding-top: 20px;
    p {
      font-size: 16px;
      font-family: "regular";
      color: #818181;
      text-align: center;
    }
  }

  .otpInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;

    input {
      width: 50px;
    }
  }
  .btn {
    padding-top: 25px;
  }
}

//rfp form
.rfpForm {
  padding: 0px 10px;

  h3 {
    font-size: 26px;
    font-family: "bold";
    text-align: center;
    padding-bottom: 10px;
  }
  .rfpBtn {
    margin: auto;
    width: 30%;
  }
}

.inputs2 {
  input {
    margin-top: 30px;
    border: 1px solid #b4b4b4 !important;
    border-radius: 4px !important;
    margin-bottom: 5px;
    padding: 10px;
  }
  h4 {
    cursor: pointer;
    text-align: right;
    padding-top: 10px;
    color: #141414;
    &:hover {
      color: $secondary;
    }
  }
}
.forpadding {
  padding-top: 20px;
}
.domainmodal {
  background: $white;
  width: 25%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: fit-content;
  padding: 30px;
  border-radius: 20px;
  figure {
    cursor: pointer;
    color: red;
  }
  & > div {
    width: 100%;
    position: relative;
    figure {
      position: absolute;
      right: -20px;
      top: -20px;
    }
    .maind {
      .dModal {
        padding-top: 15px;
        border-bottom: 1px solid rgb(128 128 128 / 9%);
        h2 {
          font-size: 17px;
          padding-bottom: 8px;
          color: #141414;
          font-weight: 500;
          font-family: "bold";
        }
        .values {
          display: flex;
          align-items: center;
          text-transform: capitalize;
          justify-content: space-between;
          svg {
            // color: #B4B4B4;
            cursor: pointer;
          }
          h4 {
            font-size: 15px;
            font-family: "light";
          }
        }
      }
    }
  }
}
.counter {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

//search div

.product_srch {
  min-height: 90px;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  padding: 20px;
  position: absolute;
  width: 93%;
  max-width: 590px;
  margin-top: 10px;
  border-radius: 14px;
  .items_div{
    border-bottom: 1px solid #8080806b;
    margin-bottom: 20px;
    p{
      color: grey;
      text-align: left;
    }
  }
  ul {
    li.main_li {
      display: flex;
      align-items: center;
      margin-bottom: 11px;
      cursor: pointer;
      &:hover {
        background: #8080801a;
      }
      .li_left {
        figure {
          width: 50px !important;
          height: 50px !important;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .li_ryt {
        width: 100%;
        .stock_flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          h3 {
            font-size: 16px;
            font-family: "bold";
            word-break: break-all;
          }
      }
       
        p {
          font-size: 13px;
          color: grey;
          font-family: "regular";
        }
      }
    }
  }
  h2 {
    font-size: 14px;
    width: 100%;
    height: 100%;
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #f1f1f1 !important;
}

.res_header {
  padding: 24px 27px;
  figure.res_logo {
    width: 140px;
    height: 90px;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .hBRight {
    padding-top: 38px;
    ul {
      li.cart {
        display: flex;
        width: 100%;
        /* background-color: #ebebeb; */
        align-items: center;
        gap: 5px;
        padding: 14px 0px 14px 12px;
        border-bottom: 2px solid #ebebeba1;
      }
      .usr_select {
        margin: 18px 0;
        .user_data {
          button {
            width: 100%;
            display: flex;
            align-items: center;
            border: 1px solid #48b1e5;
            border-radius: 4px;
            padding: 7px 7px;
            .user_img {
              figure {
                width: 45px;
                height: 45px;
                img {
                  object-fit: cover;
                  border-radius: 50%;
                }
              }
            }
            h5 {
              margin-left: 18px;
            }
          }
        }
      }
      .pst {
        margin-top: 12px;
      }
    }
  }
}
@media (max-width: 992px) {
  .headermain {
    .headerBtm {
      .hbmain {
        .hBRight {
          ul {
            display: none;
          }
        }
      }
    }
  }
  .footermain {
    .topbar {
      .topmain {
        .tRight {
          .inputField {
            button {
              width: 75px !important;
            }
          }
        }
      }
    }
  }
  .headermain {
    .headerBtm {
      .hbmain {
        display: block;
        .hBleft {
          figure {
            display: none;
          }
          .inputField {
            .product_srch {
              .main_li {
                .li_left {
                  figure {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .signmain {
    .signleft {
      h2 {
        font-size: 26px;
      }
      p {
        font-size: 18px;
      }
    }
    .signRyt {
      h3 {
        font-size: 25px;
      }
    }
  }
  .otpInput > div {
    justify-content: space-between;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .domainmodal {
    width: 70%;
  }
  .signmain {
    .signleft {
      display: none;
    }
    .signRyt {
      .crossbtn {
        display: block;
        position: absolute;
        right: 10px;
        top: 12px;
        button {
          background: linear-gradient(
              rgb(96, 69, 160) 0%,
              rgb(71, 184, 233) 100%
            )
            0% 0% no-repeat padding-box padding-box transparent;
          padding: 3px;
          svg {
            font-size: 20px;
          }
        }
      }
      width: 100%;
      padding: 30px 30px 0 30px;
      height: calc(100vh - 24px);
      position: relative;
      .bottomline {
        p {
          padding-bottom: 20px !important;
        }
      }
    }
  }
  .footermain {
    .topbar {
      .topmain {
        display: block;
        padding: 22px 0;
        .tleft {
          width: 100%;
          justify-content: center;
          margin-bottom: 15px;
        }
        .tRight {
          width: 100%;
          .inputField {
            button {
              width: 100px !important;
            }
          }
        }
      }
    }
    .centerdiv {
      ul {
        li {
          width: 40%;
          &:nth-child(1) {
            width: 60%;
          }
          .social_icons {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .otpModal {
    .otpInput {
      input {
        margin: 0 !important;
      }
    }
  }
  .signmain {
    .signRyt {
      .inputdiv {
        .firstname {
          display: block;
          .fdiv {
            width: 100%;
          }
        }
      }
      h3 {
        font-size: 20px;
      }
    }
  }
  .container {
    width: 80%;
    margin: 0 auto;
  }
  .headermain {
    .headertop {
      .htmain {
        display: block;
        text-align: center;
        .htleft {
          figure {
            display: none;
          }
        }
        .htright {
          ul {
            justify-content: center;
            li {
              white-space: nowrap;
            }
          }
        }
      }
    }
    .headerBtm {
      .hbmain {
        display: block;
        .hBleft {
          width: 100%;
          .inputField {
            input {
              width: fit-content;
              min-width: 100%;
            }
          }
        }
      }
    }
  }
  .footermain {
    .centerdiv {
      text-align: center;
      ul {
        li {
          width: 100%;
          &:nth-child(1) {
            width: 100%;
          }
          figure {
            margin: 0 auto 13px;
          }
          p {
            width: 100%;
          }
          .social_icons {
            justify-content: center;
            figure {
              margin: inherit;
              margin-right: 14px;
            }
          }
        }
      }
    }
  }
  .loginmodal {
    .fordiv {
      display: block;
    }
    p {
      line-height: 1.4;
    }
  }
  .inputs2 {
    h4 {
      font-size: 15px;
    }
  }
}

.cookie {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 70px;
  align-items: center;
  background: #61439F;
  color: white;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media (max-width:767px) {
    padding: 5px 20px;
    margin-bottom: 10px;
}
  p{
    width: 90%;
    @media (max-width:767px) {
        margin-bottom: 10px;
        width: 100%;
    }
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }
  button{
    background: rgb(71, 184, 233);
    font-family: regular;
    font-weight: normal;
    font-size: 16px;
    text-transform: capitalize;
    width: auto;
    padding: 9px;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
    @media (max-width:767px) {
      margin: auto;
  }
  
  }
}