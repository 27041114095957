@import '../../assets/theme/Variable.scss';
.pMain{
    display: flex;
    justify-content: space-between;
    .pLeft{
        width: 40%;
        p{
            font-size: 16px;
            text-align: center;
            color: grey;
        
          }
        .slick-slider.slick-initialized{
            height: 100%;
            .slick-slide.slick-active.slick-current {
                height: 100% !important;
                figure{
                    height: 100%;
                    background: white;
                    img{
                        // height: 100%;
                    }
                }
            }
         
        }
        .slick-prev {
            left: 7px !important;
            z-index: 1;
            // opacity: 0;
            &::before{
                color: #262626 !important;
                border: 5px solid white !important;
                border-radius: 100% !important;
                font-size: 30px !important;
            }
        }
        .slick-next {
            right: 33px;
            z-index: 1;
            // opacity: 0;
            &::before{
                color: #262626 !important;
                border: 5px solid white !important;
                border-radius: 100% !important;
                font-size: 30px !important;
                // color: white;
                // border: 5px solid white !important;
                // border-radius: 100% !important;
                // font-size: 30px !important;
                // background: #262626 !important;
            }

        }
        .slick-slide img {
            height: 450px;
            object-fit: contain;
            border-radius: 4px !important
        }
    }
    .pRight{
        width: 58%;
        width: 58%;
        max-height: 450px;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 0px !important;
          }
        .info {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .infoLeft {
                width: 80%;
                h3{
                    font-size: 26px;
                    font-family: 'bold';
                }
                p{
                    color: #707070;
                    font-size: 14px;
                    padding-top: 10px;
                    padding-bottom: 20px;
                    font-family: 'regular';
                }
                h5{
                    font-size: 15px;
                    font-family: 'bold';
                    padding-bottom: 5px;
                }
            }
            .infoRight {
                width: 20%;
                figure {
                    width: 140px;
                }
            }
        }
        .cartData {
            border-top: 1px solid #ddd;
            padding-top: 15px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .feature {
                width: 80%;
                p{
                    color: #4E4E4E;
                    font-size: 14px;
                    padding-bottom: 10px;
                    font-family: 'regular';
                    span{
                        font-family: 'bold';
                    }
                }
                a {
                    font-size: 14px;
                    color: $secondary;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .description {
                    margin-top: 20px;
                    border-top: 1px solid #ddd;
                    padding-top: 20px;
                    h3 {
                        font-size: 16px;
                        padding-bottom: 10px;
                        font-family: 'bold';
                    }
                    p{
                        line-height: 20px;
                        font-family: 'regular';
                        span{
                            font-family: 'bold';
                        }
                    }
                }
            }
            .call{
                box-shadow: 0px 2px 16px #00000029;
                border-radius: 5px;
                padding: 12px;
                display: flex;
                margin-bottom: 10px;
                align-items: center;
                cursor: pointer;
                .cLeft{
                    padding-left: 10px;
                    svg{
                        font-size: 19px;
                        color: $secondary;
                        padding-right: 10px;
                        margin-top: 5px;
                    }
                }
                .cRight{
                
                    h6{
                        font-size: 14px;
                        white-space: nowrap;
                        font-family: 'bold';
                    }
                }
            }
        }
    }
}
.addcart_main{
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 4;
    max-width: 100%;
    box-shadow: 0px -3px 16px #00000014;
    background: #fff;   

.addCart {
    
    padding: 12px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    
    // position: fixed;
    // bottom: 0;
    // width: 100%;
    // box-sizing: border-box;
    // left: 0;
    // right: 0;
    // z-index: 1;
    .acLeft{
        display: flex;
        
        h3 {
            font-size: 18px;
            font-family: 'bold';
        }
        button{
            background: #DEDEDE;
            border: 1px solid #DEDEDE;
            color: #fff;
            font-size: 20px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;
            svg{
                font-size: 14px;
            }
            &:hover{
                background: $secondary;
            }
        }
        input{
            border: 1px solid #fff;
            width: 20px;
            color: #000;
            font-size: 13px;
            outline: none;
            text-align: center;
            font-weight: bold;
            width: 30px;
            border-top: 1px solid #80808036;
            border-bottom: 1px solid #80808036;
        }
    }
    .acRight {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        h5{
            color: #61439f;
            font-weight: normal;
            text-decoration: underline;
            padding-right: 20px;
            cursor: pointer;
            font-family: 'bold';
        }
        button.stock_out {
            cursor: pointer;
            background: #ff3131;
            font-family: regular;
            font-size: 16px;
            text-transform: capitalize;
            width: 30%;
            color: white;
            border-radius: 5px;
            outline: none;
            border: none;
            padding: 12px;
            cursor: no-drop;
        }
        .btn {
            width: 30%;
        }
    }
}
}


.sproduct {
    margin-top: 20px;
    .tilebar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
            font-size: 18px;
            font-family: 'bold';
            color: $grey;
            cursor: pointer;
            &:hover{
                color: $secondary;
            }
        }
    }
    .prdocts {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        .proCard{
            width: 19%;
        }
    }
}

.certi_modal{
    position: relative !important;
    svg{
    position: absolute;
    right: -25px;
    top: -26px;
    cursor: pointer;
    color: red;
    }
    figure,img{
        width: 100%;
        height: 100%;
    }
}

.acLeft2 {
       
       display: flex;
       width: 80%;
       gap: 20px;
      h3{
        @media (max-width: 992px) {
            font-size: 16px !important;
           }
      }
}
.acRight2 {
    width: 19%;
}


@media (max-width: 1200px) {
    .sproduct {
        .tilebar {
            .titleBar {
                padding: 20px 0 20px 0;
            }
        }
        .prdocts {
            .proCard {
                width: 30%;
            }   
        }
    }
    .pMain {
        .pRight {
            .info {
                .infoRight {
                    width: inherit;
                }
            }
            .cartData {
                display: block;
                .call {
                    width: 196px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .sproduct {
        .prdocts {
            .proCard {
                width: 48%;
            }   
        }
    }
    .addcart_main {
        .addCart {
            .acRight {
                width: 75%;
            }
        }
    }
    .pMain {
        .pLeft {
            width: 55%;
        }
        .pRight {
            width: 42%;
            .info {
                .infoRight {
                    figure {
                        width: 108px;
                    }
                }
            }
            
        }
    }
}
@media (max-width: 576px) {
    .addcart_main {
        .addCart {
            display: block;
            .acLeft {
                justify-content: center;
            }
            .acRight {
                width: 100%;
                margin-top: 16px;
                justify-content: center;
            }
        }
    }
    .pMain {
        display: block;
        .pLeft {
            width: 100%;
            .slick-slide {
                img {
                    height: 280px;
                }
            }
        }
        .pRight {
            width: 100%;
            .info {
                margin-top: 12px;
                .infoLeft {
                    h3 {
                        font-size: 20px;
                    }
                }
                .infoRight {
                    figure {
                        width: 108px;
                    }
                }
            }
            .cartData {
                .feature {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .addcart_main {
        .addCart {
            .acRight {
                display: block;
                text-align: center;
                h5 {
                    margin-bottom: 12px;
                }
                .btn {
                    width: 100%;
                }
                button.stock_out {
                    width: 100%;
                }
            }

        }
    }
    .pMain {
        .pLeft {
            .slick-slide {
                img {
                    height: 150px;
                }
            }
        }
    }
    .sproduct {
        .tilebar {
            display: block;
            .titleBar {
                padding: 28px 0 5px 0;
                h2 {
                    font-size: 20px;
                }
            }
            h4 {
                margin-bottom: 12px;
            }
        }
        .prdocts {
            .proCard {
                width: 100%;
            }   
        }
    }
}