.manageAddress {
  h3 {
    color: #61439f;
    font-family: "bold";
    font-size: 22px;
  }

  padding-top: 0px !important;
  .addHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;

    .addBtn {
      width: 15%;
    }
  }

  .addrData {
    padding-bottom: 6px;
    display: flex;
    overflow-x: scroll;
    padding-top: 5px;
    margin-bottom: 20px;
    -webkit-overflow-scrolling: inherit;
    &::-webkit-scrollbar {
      width: 3px !important;
    }
    ul {
      border-radius: 5px;
      padding: 8px;
      font-size: 13px;
      font-family: "regular";
      line-height: 25px;
      background-color: #ffffff;
      margin: 8px;
      color: #818181;
      .icon_div {
        display: flex;
        justify-content: flex-end;
        svg {
          font-size: 20px;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      :nth-child(1),
      :nth-child(2) {
        font-family: "bold";
      }

      li {
        width: 350px;
      }
    }
    ul.brdr_add {
      outline: 1px solid #61439f;
    }
  }

  .addrData::-webkit-scrollbar {
    height: 3px;
  }
  .inputdiv {
    margin-left: 8px;
    .phoneInput {
      padding-left: 50px !important;
      width: 100% !important;
      height: 50px;
    }
    .phoneBtn {
      padding: 10px 1px !important;
      margin-top: -5px !important;
    }
    .firstname {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      .fdiv {
        width: 49.4%;
        input {
          border: 1px solid #b4b4b4 !important;
          border-radius: 4px !important;
          margin-bottom: 12px;
          padding: 12px !important;
        }
      }
    }

    input {
      background-color: #ffffff !important;
    }
  }
}

//change pass
.changePass {
  padding-top: 0px !important;
  h3 {
    font-size: 22px;
    font-family: "bold";
    color: #61439f;
    margin-left: 8px;
    padding-bottom: 10px;
  }

  .inputdiv {
    margin-left: 8px;
    .firstname {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .fdiv {
        width: 49.4%;
        input {
          border: 1px solid #b4b4b4 !important;
          border-radius: 4px !important;
          margin-bottom: 12px;
          padding: 12px !important;
        }
      }
    }

    input {
      background-color: #ffffff !important;
    }
  }
}
