@import "../../assets/theme/Variable.scss";
.aboutWrapper {
  .aboutBannr {
    background-image: url("../../../public/static/images/about_bnnr.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    text-align: center;
    padding: 140px 0px;
    // height: 100vh;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    h1 {
      font-family: "bold";
      font-size: 60px;
      padding-top: 200px;
    }
    p {
      font-family: "regular";
      padding-top: 20px;
      font-size: 18px;
      text-align: center;
    }
    .bannerText {
      width: 30%;
      margin: auto;
    }
  }
  .bnnrBtn {
    text-align: center;
    background-color: #61439f;
    color: #ffffff;
    padding: 20px 0px;
    h2 {
      font-family: "bold";
      font-size: 32px;
    }
    .btnn {
      width: 10%;
      margin: auto;
      padding-top: 15px;
      button {
        background: $white !important;
        color: $black !important;
        border: 1px inset transparent;
        &:hover {
          color: $white !important;
          background: $secondary !important;
          border: 1px inset $white;
        }
      }
    }
  }
  .aboutDiv {
    padding-top: 40px;
    padding-bottom: 50px;
    margin-top: 160px;
    h3 {
      text-align: center;
      font-size: 35px;
      color: $black !important;
      font-family: "bold";
    }
    .content {
      line-height: 25px;
      font-family: "regular";
      font-size: 18px;
      width: 96%;
      h3 {
        text-align: center;
        font-size: 22px;
        color: $black !important;
        font-family: "regular";
        padding-bottom: 10px;
      }
      p{
        text-align: center;
        padding-top: 10px;
      }
      .aboutDetails {
        padding-top: 30px;
        text-align: center;
        font-family: "regular";
      }
    }
  }
}
// private policy
.policyWrapper {
  .prvtPolicy {
    text-align: center;
    padding-top: 60px;
    h2 {
      font-size: 32px;
      font-family: "bold";
      color: $black !important;
      margin-bottom: 20px;
    }
    p{
      text-align: center;
      padding-bottom: 20px !important;
    }
    .policyContent {
      line-height: 25px;
      font-family: "regular";
      font-size: 18px;
      width: 96%;
      margin-bottom: 30px;
      p{
        h1{
          padding-bottom: 30px;
        }
      }
      .policyDetails {
        padding-top: 30px;
        text-align: center;
      }
    }
  }
  .policyContent {
    line-height: 25px;
    font-family: "regular";
    font-size: 18px;
    width: 96%;
    margin-bottom: 30px;
    .policyDetails {
      padding-top: 30px;
      text-align: center;
    }
  }
  .prsnlData {
    padding-top: 60px;
    h3 {
      font-size: 30px;
      font-family: "bold";
    }
    p {
      line-height: 25px;
      font-family: "regular";
      font-size: 18px;
      width: 96%;
      padding-top: 20px;
    }
    .dataPoints {
      padding-bottom: 50px;
      h4 {
        font-family: "bold";
        font-size: 22px;
        padding-top: 40px;
      }
    }
  }
}
//Contact Us
.contactWrapper {
  .contactHead {
    padding-top: 50px;
    h2 {
      font-family: "bold";
      font-size: 38px;
      text-align: center;
    }
    p {
      font-family: "regular";
      font-size: 18px;
      padding-top: 20px;
      text-align: center;
    }
  }
  .googleMap {
    padding-top: 50px;
    h3 {
      font-size: 33px;
      padding-top: 30px;
      font-family: "bold";
    }
    p {
      font-size: 21px;
      color: #4e4e4e;
      padding-top: 5px;
      font-family: "regular";
    }
    span {
      // padding-left: 100px;
      font-size: 21px;
      color: #4e4e4e;
      font-family: "regular";
    }
    .contactInfo {
      display: flex;
      justify-content: space-between;
      width: 40%;
    }
  }
  .contactForm {
    padding-top: 50px;
    h4 {
      font-family: "bold";
      font-size: 34px;
    }
    form {
      padding-top: 20px;
    }
    .btn {
      padding-top: 20px;
      width: 10%;
    }
  }
}
@media (max-width: 992px) {
  .aboutWrapper {
    .aboutBannr {
      padding: 70px 0px 90px;
      h1 {
        font-size: 50px;
      }
      .bannerText {
        width: 100%;
        p {
          font-size: 16px;
        }
      }
    }
    .bnnrBtn {
      h2 {
        font-size: 26px;
      }
      .btnn {
        width: 140px;
      }
    }
    .aboutDiv {
      h3 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 767px) {
  .contactWrapper {
    .contactHead {
      h2 {
        font-size: 24px;
        margin-top: 18px;
      }
      p {
        font-size: 16px;
      }
    }
    .googleMap {
      h3 {
        font-size: 18px;
      }
      .contactInfo {
        p {
          font-size: 16px;
        }
      }
      span {
        padding-left: 0px;
      }
    }
    .contactForm {
      h4 {
        font-size: 24px;
      }
      .btnddiv {  
        width: 200px;
      }
    }
  }
  .policyWrapper {
    .prvtPolicy {
      h2 {
        font-size: 24px;
      }
    }
  }
}
@media (max-width: 480px) {
  .contactWrapper {
    .contactHead {
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }
    .googleMap {
      h3 {
        font-size: 18px;
      }
      .contactInfo {
        p {
          font-size: 16px;
        }
      }
    }
    .contactForm {
      h4 {
        font-size: 18px;
      }
    }
  }
  .aboutWrapper {
    .aboutBannr {
      h1 {
        font-size: 40px;
      }
    }
    .bnnrBtn {
      h2 {
        font-size: 22px;
      }
    }
    .aboutDiv {
      h3 {
        font-size: 22px;
      }

    }
  }
  .policyWrapper {
    .prvtPolicy {
      h2 {
        font-size: 18px;
      }
    }
  }
}