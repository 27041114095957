@import "../../assets/theme/Variable.scss";
.profilemain {
  padding: 70px 0;
  .pImg {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    figure {
      width: 100px;
      height: 100px;
      border-radius: 5px;
      position: relative;
      img {
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
      .edit {
        width: 20px;
        position: absolute;
        left: 40px;
        bottom: -13px;
        svg {
          font-size: 19px;
          background: white;
          border-radius: 100%;
          padding: 3px;
          cursor: pointer;
        }
      }
    }
    h3 {
      margin-left: 20px;
    }
  }
  .inputdiv {
    .errMsg {
      color: red;
      font-size: 13px;
      font-weight: 500;
      font-family: "regular";
      line-height: 1px;
      padding-bottom: 26px;
      padding-top: 5px;
      text-align: left;
    }
    .firstname {
      display: flex;
      justify-content: space-between;

      .fdiv {
        width: 49.4%;
        input {
          border: 1px solid #b4b4b4 !important;
          border-radius: 4px !important;
          margin-bottom: 12px;
          padding: 12px !important;
          background-color: #ffffff;
        }
        .phoneInput {
          padding-left: 50px !important;
          width: 100% !important;
          height: 50px;
        }
        .phoneBtn {
          padding: 10px 1px !important;
          margin-top: -5px !important;
        }
      }
    }
    .phoneInput {
      padding-left: 50px !important;
      width: 100% !important;
      height: 50px;
    }
    .phoneBtn {
      padding: 10px 1px !important;
      margin-top: -5px !important;
    }
    input {
      border: 1px solid #b4b4b4 !important;
      border-radius: 4px !important;
      margin-bottom: 12px;
      padding: 12px !important;
      background-color: #ffffff;
    }
    textarea {
      border: 1px solid #b4b4b4 !important;
      border-radius: 4px !important;
      margin-bottom: 12px;
      padding: 12px !important;
    }
    figure {
      width: 30px;
      height: 30px;
    }
    .btn {
      width: 20%;
      padding-top: 15px;
      font-family: "bold";
    }
    .checkbox {
      display: flex;
      align-items: center;
      span {
        padding: 0 !important;
      }
      h5 {
        font-size: 14px;
        padding-left: 7px;
        font-family: "regular";
        color: #818181;
      }
    }
  }
}

.profilemain.info {
  padding: 0;
}
.btnddiv {
  // width: 17%;
  @media (max-width: 767px) {
    width: 100%;
  }
  button {
    background: linear-gradient(rgb(96, 69, 160) 0%, rgb(71, 184, 233) 100%) 0%
      0% no-repeat padding-box padding-box transparent;
    font-family: "regular";
    font-size: 16px;
    text-transform: capitalize;
    width: 100%;
    padding: 10px 8px;
    color: white;
    font-size: 18px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
        rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    }
  }
}

.btnddiv2 {
  width: 17%;
  @media (max-width: 767px) {
    width: 200px;
  }
  button {
    background: linear-gradient(rgb(96, 69, 160) 0%, rgb(71, 184, 233) 100%) 0%
      0% no-repeat padding-box padding-box transparent;
    font-family: "regular";
    font-size: 16px;
    text-transform: capitalize;
    width: 100%;
    padding: 10px 8px;
    color: white;
    font-size: 18px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
        rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    }
  }
}

@media (max-width: 767px) {
  .profilemain {
    .inputdiv {
      .btn {
        width: 56%;
      }
    }
  }
}
@media (max-width: 480px) {
  .profilemain {
    .inputdiv {
      .firstname {
        display: block;
        .fdiv {
          width: 100%;
        }
      }
    }
  }
}
