.accMain {
    display: flex;
    padding-top: 80px;
    justify-content: space-between;
    padding-bottom: 50px;
    .accleft {
        width: 25%;
        .sidebarmain {
            .username {
                border: 1px solid #F0F0F0;
                padding: 10px;
                border-radius: 4px;
                .figleft {
                    display: flex;
                    align-items: center;
                    figure {
                        width: 45px;
                        height: 45px;
                        margin-right: 20px;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                            object-fit: cover;
                        }
                    }
                    h3 {
                        font-family: 'bold';
                        font-size: 19px;
                    }
                }
            }
            .sidebar{
                border: 1px solid #F0F0F0;
                border-radius: 4px;
                margin-top: 30px;
                ul {
                    border-bottom: 1px solid #F0F0F0;
                    &.logoutdiv{
                        border-bottom: 1px solid transparent;
                        padding-bottom: 20px;
                    }
                    li {
                        .accmain {
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            padding: 13px 10px;
                            figure {
                                width: 20px;
                                height: 20px;
                               
                            }
                            h3 {
                                color: #818181;
                                font-family: 'bold';
                                padding-left: 10px;
                                color: #141414;
                                font-size: 16px;
                            }
                        }
                        .accmain2{
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            padding: 13px 10px;
                            figure {
                                width: 20px;
                                height: 20px;
                                img{
                                    filter:grayscale(317%) brightness(140%) ; 
                                 }
                            }
                            h3 {
                                color: #818181;
                                font-family: 'light';
                                padding-left: 10px;
                                font-size: 16px;
                                // &:hover{
                                //     color: #141414;
                                //     font-family: 'bold';
                                // }
                            }
                        }
                    }
                }
            }
        }
    }
    .accright {
        width: 73%;
        background: #F7F7F7;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
}