

.bottombar {
    background:#61439F;
    padding: 10px 0;
    h5 {
      font-size: 14px;
      text-align: center;
      color: #ffff;
      font-family: 'light';
    }
  }