@import "../../assets/theme/Variable.scss";

.orderlistmain {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  .DataSection {
    width: 49%;
    // max-height: 490px;
    // overflow-y: scroll;
    // overflow-x: hidden;
    scrollbar-width: 0 !important;
    &::-webkit-scrollbar {
      width: 0px !important;
      margin-left: 20px !important;
    }
    .button-div {
      padding: 5px;
      border: 1px solid #dedede;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
    }
    .order-btn {
      background-color: #dedede;
      border: none;
      border-radius: 5px;
      padding: 14px 45px;
      width: 285px;
      cursor: pointer;
      font-size: 15px;
      width: 50%;
      margin-right: 6px;
    }
    .active {
      width: 49%;
      background-color: #dedede;
      border: none;
      border-radius: 5px;
      padding: 14px 45px;
      cursor: pointer;
      font-size: 15px;
      background: $secondary;
      color: white;
      width: 49%;
      margin-right: 6px;
    }
    .orders-data {
      display: flex;
      padding: 4px;
      border: 1px solid #dedede;
      margin: 10px 0px;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
    }
    .orders-data_slt{
      display: flex;
      padding: 4px;
      border: 1px solid $secondary;
      margin: 10px 0px;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
    }

    .button-div {
      padding: 5px;
      border: 1px solid #f0f0f0;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      position: sticky;
      width: 100%;
      top: 0;
      z-index: 1;
      background: white;
    }

    .order-status {
      position: absolute;
      background-color: #008000;
      right: 0px;
      bottom: 0px;
      padding: 3px 25px 3px 5px;
      color: #ffffff;
      border-radius: 0px 0px 5px 0px;
    }
  }
}

.orders-data {
  display: flex;
  padding: 4px;
  border: 1px solid #dedede;
  margin-bottom: 15px;
  border-radius: 8px;
  position: relative;
  .order-img {
    width: 160px;
    padding: 4px;
    padding-right: 10px;
    height: 140px;
  }
  .product-details {
    font-size: 12px;
    margin-top: 6px;
    line-height: 20px;
    width: 70%;
    h4 {
      color: $secondary;
      font-size: 16px;
      padding-bottom: 5px;
      font-family: "bold";
    }
    p {
      color: $grey;
      font-size: 15px;
      padding-bottom: 6px;
      width: 80%;
      font-family: "regular";
    }
    span {
      color: $secondary;
      font-family: "bold";
      font-size: 14px;
    }
  }
}
.orders-data_slt {
  display: flex;
  padding: 4px;
  border: 1px solid #dedede;
  margin-bottom: 15px;
  border-radius: 8px;
  position: relative;
  .order-img {
    width: 160px;
    padding: 4px;
    padding-right: 10px;
    height: 140px;
  }
  .product-details {
    font-size: 12px;
    margin-top: 6px;
    line-height: 20px;
    width: 70%;
    h4 {
      color: $secondary;
      font-size: 16px;
      padding-bottom: 5px;
      font-family: "bold";
    }
    p {
      color: $grey;
      font-size: 15px;
      padding-bottom: 6px;
      width: 80%;
      font-family: "regular";
    }
    span {
      color: $secondary;
      font-family: "bold";
      font-size: 14px;
    }
  }
}

.orderDetails {
  // background-color: #f7f7f7;
  // border-radius: 8px;
  // padding: 15px;
  font-family: "regular";
  .card1, .card2 {
    background-color: #f7f7f7;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
}
  h5 {
    font-size: 16px;
    color: #4e4e4e;
  }
  .ordermain {
    display: "flex";
    justify-content: "space-between";
  }

  .totalAmount {
    display: flex;
    justify-content: space-between;
    font-family: "bold";
    p {
      color: #61439f;
    }
  }
}

.dividr {
  border: 1px solid #b2b2b2;
  margin: 10px 0px;
}

.visa-img {
  width: 60px;
  height: 20px;
  padding: 10px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  background: white;
}

.cancel-btn {
  background-color: #ff3131;
  border: none;
  padding: 15px 40px;
  color: #ffffff;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 20px;
}
.cancel-btn2{
  background-color: white;
  border: none;
  padding: 15px 30px;
  color: #ff3131;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 20px;
  border: 1px solid #ff3131;
}

.details-status .status-p {
  font-family: "bold";
  color: #008000;
}

.details-status {
  text-align: end;
}

.details-status .status-p-2 {
  font-family: "bold";
  color: #ff3131;
}

.order-id {
  line-height: 30px;
  h3 {
    font-size: 28px;
    font-family: "bold";
  }
  p {
    font-size: 16px;
    font-family: "regular";
  }
}
.headd {
  h6 {
    font-family: "bold";
    font-size: 18px;
  }
}

.payment-details {
  padding-left: 10px;
}

.DetailsSection {
  width: 49%;
}

.rateing {
  button {
    font-family: "regular" !important;
    background: linear-gradient(rgb(96, 69, 160) 0%, rgb(71, 184, 233) 100%) 0%
      0% no-repeat padding-box padding-box transparent;
    font-family: regular;
    font-weight: normal;
    font-size: 16px;
    text-transform: capitalize;
    width: 100%;
    padding: 8px;
    margin-top: 20px;
    color: white;
    border: none;
    padding: 11px;
    border-radius: 4px;
    width: 40%;
    cursor: pointer;
  }
}

.auth {
  h3.logout2 {
    text-align: center;
    font-size: 18px;
    color: rebeccapurple;
  }
  .rating {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .fControl{
    .text_area{
      border: 1px solid #b4b4b4 !important;
      border-radius: 4px !important;
      margin-bottom: 12px;
      padding: 12px !important;
      box-sizing: border-box;
      margin-top: 10px;
    }
  }
  .submitBtn {
    display: flex;
    justify-content: center;
    button {
      font-family: "regular" !important;
      background: linear-gradient(rgb(96, 69, 160) 0%, rgb(71, 184, 233) 100%) 0%
        0% no-repeat padding-box padding-box transparent;
      font-family: regular;
      font-weight: normal;
      font-size: 16px;
      text-transform: capitalize;
      width: 100%;
      padding: 8px;
      margin-top: 20px;
      color: white;
      border: none;
      padding: 11px;
      border-radius: 4px;
      width: 40%;
      cursor: pointer;
    }
  }
}

.no_order_div {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  figure{
    width: 50px;
    height: 50px;
  }
  h4{
    font-size: 16px;
    font-family: "regular";
    color: rgba(128, 128, 128, 0.88);
  }
}

@media (max-width: 992px) {
  .orderlistmain {
    display: block;
    .DataSection {
      width: 100%;
    }
    .DetailsSection {
      width: 100%;
    }
  }
}
@media (max-width: 480px) {
  .order-id {
    h3 {
      font-size: 20px;
    }
  }
  .orderlistmain {
    .DataSection {
      .active {
        width: 100%;
        padding: 14px 4px;
      }
      .order-btn {
        padding: 14px 4px;
        width: 100%;
      }
    }
  }
  .orders-data_slt {
    .order-img {
      width: 130px;
      height: 120px;
    }
  }
}