.notfound{
    min-height: 100vh;
    display: flex;
    align-items: center;
    .btn {
        background: #61439F !important;  
        color: #fff;
        padding: 7px 14px;
        border: none;
        font-size: 18px;
        font-weight: 600;
        border-radius: 2px;
        cursor: pointer;
        min-width: 120px;
    
      }
    @media (max-width:767px){
        padding: 50px 0;
    }
h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight:bold;
    color: #61439F;
    @media (max-width:767px){
        font-size: 40px;
    }
  }
  h2 {
    font-weight:bold;
    font-size: 33px;
    color: #61439F;
  }
  p{
    margin: 30px 0;
    color: #61439F;
    line-height: 30px;
    font-size: 18px;
  }
  
}

  .custome_row > *{
    width: 48%;
    figure{
      width: 500px;
      height: 500px;
      img {
        width: 100%;
        filter: hue-rotate(50deg);
        height: 100%;
    }
    }
    @media (max-width:767px){
        width: 100%;
        text-align: center;
    }
  }
  .custome_row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

