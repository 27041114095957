@import "../../assets/theme/Variable.scss";
.manageOrders {
  h3 {
    font-family: "bold";
    color: #61439f;
    font-size: 22px;
    padding-bottom: 20px;
  }
  .mngOrdrSec {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    .order_table {
      width: 100%;
      position: relative;
    }
    .mainOrdr {
      border: 1px solid #b4b4b4;
      padding: 14px;
      width: 48%;
      line-height: 20px;
      border-radius: 5px;
      background-color: #ffffff;
      position: relative;
      box-sizing: border-box;
      .topSec {
        display: flex;
        .image {
          figure {
            width: 84px;
            height: 70px;
            padding-right: 10px;
          }
        }
        .rightContent {
          h4 {
            font-size: 16px;
            font-family: "bold";
            color: #61439f;
          }
          p {
            font-size: 14px;
            font-family: "regular";
            color: #707070;
            padding-top: 5px;
          }
          span {
            font-family: "regular";
            color: #141414;
            font-size: 14px;
          }
        }
      }
      .bottomSec {
        .amount {
          display: flex;
          justify-content: space-between;
          p {
            color: #61439f;
            font-size: 14px;
            font-family: "regular";
            padding-top: 5px;
          }
        }
        .status {
          padding-top: 5px;
          span {
            font-family: "regular";
            font-size: 14px;
            color: #0c1f00;
          }
        }
        .ordrStatus {
          position: absolute;
          right: 0px;
          bottom: 0px;
          p {
            font-size: 12px;
            font-family: "regular";
            color: #ffffff;
            padding: 4px 15px;
            border-radius: 0px 0px 3px 0px;
          }
        }
      }
    }
  }
}
//manage Product
.manageProducts {
  //   padding: 20px 0px;
  .productHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 23px;
      font-family: "bold";
      color: #61439f;
      // width: 23%;
    }
    .rightp {
      display: flex;
      width: 66%;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: 1200px) {
        width: 70%;
      }
      h4{
        display: flex;
        font-size: 15px;
        // width: 33%;
        margin-right: 10px;
      color: $secondary;
      cursor: pointer;
      }
      .bulk {
        // width: 30%;
        background: white;
        box-shadow: 0px 2px 16px #00000014;
        border-radius: 3px;
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        justify-content: center;
        margin-right: 10px;
        position: relative;
        label{
          width: 100%;
          height: 100%;
          cursor: pointer;
          .uploadImg{
            width: 100%;
            height: 100%;
            cursor: pointer;
            input{
              width: 100%;
              height: 100%;
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }
            .bussinessimg{
              display: flex;
              p {
                font-family: "regular";
                padding-right: 10px;
                color: #141414;
              }
            }
          }
        }
      
      }
      .btn {
     button{
      padding: 8px 30px !important;
      @media (max-width: 1366px) {
        padding: 8px 23px !important;
      }
      @media (max-width: 1200px) {
        padding: 8px 10px !important;
      }
     }
      }
    }
  }
  .pDetail {
    padding-top: 30px;
    .pdetailmain {
      .proimgs {
        display: flex;
        gap: 20px;
        align-items: center;
        figure {
          width: 24%;
          height: 160px;
          border-radius: 5px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
        justify-content: space-between;
        li {
          width: 24%;
          padding-bottom: 40px;
          word-wrap: break-word;
          // margin-right: 5px;
          h3 {
            color: #848484;
            font-family: "light";
            font-size: 16px;
          }
          p {
            color: #141414;
            font-size: 15px;
            font-family: "bold";
            padding-top: 12px;
          }
        }
      }
      .specification {
        padding-bottom: 30px;
        h4 {
          color: #848484;
          font-family: "light";
          font-size: 16px;
          // padding-bottom: 12px;
        }
        p {
          color: #141414;
          font-size: 15px;
          font-family: "bold";
          padding-top: 12px;
        }
      }
    }
  }
  .tabdiv {
    // display: flex;
    position: relative;
    padding-top: 20px;
    .tableft {
      #simple-tabpanel-0,
      #simple-tabpanel-1,
      #simple-tabpanel-2 {
        div {
          padding: 0 !important;
          // margin-top: 20px !important;
          border-radius: 15px !important;
          .tablepanal {
            button {
              padding: 0 !important;
            }
          }
          .tablemain {
            table {
              thead {
                tr {
                  th {
                    font-family: "bold" !important;
                    font-size: 15px !important;
                  }
                }
              }
              tbody {
                tr {
                  th {
                    font-family: "regular" !important;
                    font-size: 14px !important;
                  }
                  td {
                    font-family: "regular" !important;
                    font-size: 14px !important;
                    svg {
                      color: #61439f !important;
                      font-size: 20px !important;
                      cursor: pointer;
                      &:nth-child(2) {
                        color: green !important;
                        padding-left: 10px !important;
                        font-size: 22px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .tablebox {
        & > div {
          margin-top: 10px !important;
          border-bottom: 0px solid !important;
          border-color: rgba(0, 0, 0, 0.12);
        }
      }
    }
    .tabryt {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      // width: 60%;
      .filtermain {
        display: flex;
        width: 100%;
        align-items: center;
        padding-top: 20px;
        justify-content: flex-end;
        input {
          box-shadow: 0px 2px 16px #00000014;
          border-radius: 3px;
          background: white;
          padding: 10px !important;
          width: 110px;
          margin-left: 10px;
          font-size: 15px;
          font-family: "regular";
        }
        .brandselect {
          background: white;
          box-shadow: 0px 2px 16px #00000014;
          margin-left: 10px;
          margin-top: 10px !important;
          border-radius: 3px;
          // padding: 10px !important;
          div {
            width: 120px !important;
            font-family: "regular";
            svg {
              right: 0px !important;
            }
          }
        }
        .cateselect {
          background: white;
          margin-top: 10px !important;
          box-shadow: 0px 2px 16px #00000014;
          margin-left: 10px;
          border-radius: 3px;
          // padding: 10px !important;
          div {
            width: 140px !important;
            font-family: "regular";
            svg {
              right: 0px !important;
            }
          }
        }
        .daterange {
          div {
            div {
              min-width: 0 !important;
            }
          }
          // div{
          //   padding-top: 0 !important;
          // }
          input {
            box-shadow: 0px 2px 16px #00000014;
            border-radius: 3px;
            background: white !important;
            padding: 10px !important;
            width: 205px;
          }
        }
      }
    }
  }
}
//pagination
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'regular';
    font-size: 18px;
}
  p {
    font-size: 14px;
    font-family: "regular";
    span {
      font-family: "bold";
    }
  }
  .pages {
    display: flex;
    svg {
      font-size: 12px;
      border: 0.5px solid #a5a5a5;
      padding: 6px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 10px;
        padding: 4px;
        margin-right: 0;
      }
    }
    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid #a5a5a5;
      @media (max-width: 480px) {
        margin-right: 0;
      }
      .actv {
        background: $secondary;
        color: $white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        @media (max-width: 480px) {
          width: 20px;
          height: 20px;
          font-size: 10px;
        }
      }
      .inActv {
        background: transparent;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        border: none;
        @media (max-width: 480px) {
          width: 20px;
          height: 20px;
          font-size: 10px;
        }
      }
    }
  }
}
//user details
.manageUser {
  .userHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 22px;
      font-family: "bold";
      color: #61439f;
      padding-left: 5px;
    }
  }
  .userDetail {
    .userMain {
      .userImg {
        figure {
          width: 180px;
          padding: 10px;
          padding-top: 20px;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          padding: 10px;
          h3 {
            color: #848484;
            font-family: "light";
            font-size: 18px;
          }
          p {
            color: #141414;
            font-size: 17px;
            font-family: "bold";
            padding-top: 12px;
          }
        }
      }
    }
  }
}
.cstm_container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
figure.certifi {
  width: 500px;
  padding-top: 20px;
  height: 250px;
  img {
    height: 100%;
    object-fit: cover;
  }
}
.noTableData {
  display: flex;
  width: 100%;
  display: flex;
  height: 100;
  height: 120px;
  justify-content: center;
  align-items: center;
  h4 {
    position: absolute;
    top: 60%;
    left: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    font-family: 'regular';
  }
}

.noTableDatarole{
  display: flex;
  width: 100%;
  display: flex;
  height: 100;
  height: 120px;
  justify-content: center;
  align-items: center;
  h4 {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    top: 60%;
    font-size: 16px;
    font-family: 'regular';
  }
}

.noTableDatarole2{
  display: flex;
  width: 100%;
  display: flex;
  height: 100;
  height: 120px;
  justify-content: center;
  align-items: center;
  h4 {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    top: 60%;
    font-size: 16px;
    font-family: 'regular';
    top: 80px;
  }
}

.order_error{
  display: flex;
  width: 100%;
  display: flex;
  height: 100;
  height: 120px;
  justify-content: center;
  align-items: center;
  h4 {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    top: 60%;
    font-size: 16px;
    font-family: 'regular';
  }
}

.order_err{
  h4{
    padding: 50px 0 !important;
    position: relative;
    left: 210%;

  }
}
.order_err2{
  h4{
    padding: 50px 0 !important;
    position: relative;
    left: 200%;
  }
}

.ta_ble{
  tbody{
    tr{
      td{
        &:nth-child(7){
          padding: 0 !important;
          padding-left: 10px !important;
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .manageProducts {
    .tabledv {
      margin-top: 58px;
    }
    
    .tabdiv  {
      .tabryt {
        top: 56px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .manageProducts {
    .tabledv {
      margin-top: 75px;
    }
    .tabdiv  {
      .tabryt {
        top: 60px;
        .filtermain {
          flex-wrap: wrap;
        }
      }
    }
    // .productHead {
    //   .rightp {
    //     width: 54%;
    //   }
    // }
    .pDetail {
      .pdetailmain {
        ul{
          li {
            width: 50%;
          }
        }
      }
    }
  }
  .manageOrders {
    .mngOrdrSec {
      .order_table {
        .MuiTableRow-head {
          white-space: nowrap;
        }
        .MuiTableRow-root {
          white-space: nowrap;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .addprdct {
    form {
      .inputdivs {
        .inputcontainer {
          width: 48%;
        }
      }
    }
  }
  .manageProducts { 
    .tabledv {
      margin-top: 121px;
    }
    .productHead {
      display: block;
      h3 {
        margin-bottom: 15px;
      }
      .rightp {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
  .manageUser {
    .userDetail {
      .userMain {
        .userImg {
          figure {
            margin: auto;
          }
        }
        ul {
          li {
            width: 33%;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  figure.certifi {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 480px) {
  .accright {
    .addprdct {
      form {
        .inputdivs {
          .inputcontainer {
            width: 100%;
          }
        }
      }
      .producthead {
        display: block;
        .btndiv {
            width: 100%;
            justify-content: flex-start;
        }
      }
    }
  }
  
  .manageProducts { 
    .productHead {
      .rightp {
    flex-wrap: wrap;
        gap: 13px;
        .bulk{
          width: 67%;
        }
        .btn{
          width: 61%;
        }
      }
    }
    .tabledv {
      margin-top: 241px;
      .noTableData {
        h4 {
          top: 76%;
        }
      }
    }
    .pDetail {
      .pdetailmain {
        ul{
          li {
            width: 100%;
          }
        }
        .proimgs {
          figure {
            width: 160px;
            height: 160px;
          }
        }
      }
    }
  }
  .manageUser {
    .userDetail {
      .userMain {
        ul {
          li {
            width: 100%;
          }
        }
      }
    }
  }
}


  h3.count_pen{
    position: absolute;
    background: #61439F;
    left: 40px;
    top: 0px;
    border-radius: 100%;
    font-size: 14px;
    padding: 3px;
    color: white;
    // width: 14px;
    // height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
