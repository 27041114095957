.faqmain {
  padding: 50px 0;

  .faqHead {
    h2 {
      padding-bottom: 16px;
      font-family: "bold";
      text-align: center;
      font-size: 32px;
    }
  }
  
  p{
    text-align: center !important;
  }
  .accrd {
    padding-top: 22px;
    p {
      font-family: "regular";
    }
  }
}
@media (max-width: 767px) {
  .faqmain {
    .faqHead {
      h2 {
        font-size: 24px;
      }
    }
  }
  p {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .faqmain {
    .faqHead {
      h2 {
        font-size: 18px;
        margin-top: 16px;
      }
    }
    p {
      font-size: 14px;
    }
  }
}