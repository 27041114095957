@import '../../assets/theme/Variable.scss';

.cartInfo{
    padding-bottom: 50px;
    table{
        width: 100%;
        position: relative;
        thead{
            background: #dedede;
            th{
                padding: 15px;
                font-size: 17px;
                text-align: left;
                width: 20%;
                font-family: 'bold';
                &:nth-child(2){
                    width: 30% ;
                }
                @media (max-width: 992px) {
                    font-size: 15px !important;
                   }
                   @media (max-width: 767px) {
                  padding: 7px;
                   }
            }
        }
        td {
            padding: 10px;
            font-size: 18px;
            font-family: 'bold';
            padding: 15px;
            border-bottom: 1px solid #4e4e4e4d;
            @media (max-width: 992px) {
                font-size: 16px !important;
               }
          
            figure{
                width: 160px;
                height: 118px;
                margin: auto;
                @media (max-width: 767px) {
                    width: 120px;
                height: 98px;
                   }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            span{
                display: block;
                font-weight: normal;
                padding-top: 10px;
                font-size: 16px;
                color: #707070;
                line-height: 21px;
                width: 80%;
                font-family: 'regular';
            }
            svg{
                // color: red;
                cursor: pointer;
            }
            .acLeft{
                display: flex;
                button{
                    background: #DEDEDE;
                    border: 1px solid #DEDEDE;
                    color: #fff;
                    font-size: 20px;
                    width: 30px;
                    height: 30px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    &:hover{
                        background: $secondary;
                    }
                }
                h3{
                    border: 1px solid #fff;
                    color: #000;
                    font-size: 13px;
                    outline: none;
                    text-align: center;
                    font-weight: bold;
                    width: 40px;
                    border-top: 1px solid #80808045;
                    border-bottom: 1px solid #80808045;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                }
            }
        }
    }
}

.addcart_main{
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 4;
    max-width: 100%;
    box-shadow: 0px -3px 16px #00000014;
    background: #fff;   

.addCart {
    padding: 12px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    .acLeft2{
        display: flex;
        
        h3 {
            font-size: 18px;
            font-family: 'bold';
        }
        button{
            background: #DEDEDE;
            border: 1px solid #DEDEDE;
            color: #fff;
            font-size: 20px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;
            svg{
                font-size: 14px;
            }
            &:hover{
                background: $secondary;
            }
        }
        input{
            border: 1px solid #fff;
            width: 20px;
            color: #000;
            font-size: 13px;
            outline: none;
            text-align: center;
            font-weight: bold;
            width: 30px;
            border-top: 1px solid #80808036;
            border-bottom: 1px solid #80808036;
        }
    }
    .acRight2 {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        h5{
            color: #61439f;
            font-weight: normal;
            text-decoration: underline;
            padding-right: 20px;
            cursor: pointer;
            font-family: 'bold';
        }
        button.stock_out {
            cursor: pointer;
            background: #ff3131;
            font-family: regular;
            font-size: 16px;
            text-transform: capitalize;
            width: 30%;
            color: white;
            border-radius: 5px;
            outline: none;
            border: none;
            padding: 12px;
            cursor: no-drop;
        }
        .btn {
            width: 30%;
        }
    }
}
}


.noProduct {
    width: 89%;
    height: 150px;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    figure {
        width: 140px;
        height: 140px;
    }
    h3{
        font-size: 18px;
        top: 50%;
        right: 45%;
        color: grey;
    }
}
@media (max-width: 1400px) {
    .checoutproduct {
        .chleft {
            width: 38%;
        }
        .chrightt {
            width: 68%;
        }
    }
}
@media (max-width: 1024px) {
    .checoutproduct {
        display: block;
        .chleft {
            width: 100%;
        }
        .chrightt {
            width: 100%;
        }
    }
}
@media (max-width: 992px) {
    .cartInfo {
        .table_rep {
            overflow-x: auto;
            width: 100%;
            tr {
                white-space: nowrap;
            }
        }
    }
}

@media (max-width: 576px) {
    .cartInfo {
        .addcart_main {
            .addCart {
                display: flex;
                .acRight2 {
                    width: 53%;
                }
            }
        }
    }
    .check_main {
        .checoutproduct {
            display: flex;
            justify-content: space-between;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(78, 78, 78, 0.231372549);
            .chleft {
                width: 40%;
            }
            .chrightt {
                width: 60%;
            }
        }
    }

    .checkdetail {
        display: block;
        .cDLeft {
            width: 100%;
            padding-top: 0;
            height: inherit;
        }
        .cDRight {
            width: 100%;
            border-left: 0;
        }
    }
}
@media (max-width: 480px) {
    .check_main {
        .checoutproduct {
            display: block;
            .chleft {
                width: 100%;
            }
            .chrightt {
                width: 100%;
            }
        }
    }
    .cartInfo {
        .addcart_main {
            .addCart {
                display: block;
                .acLeft2 {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 15px;
                }
                .acRight2 {
                    width: 100%;
                }
            }
        }
    }
}