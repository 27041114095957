.order_table {
  div {
    table {
      thead {
        tr {
          th {
            font-family: "bold";
            font-size: 14px;
            color: "#202020";
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            font-family: "regular";
            color: "#202020";
          }
        }
      }
    }
  }
}

//report

.addprdct {
  .producthead {
    h3 {
      font-size: 22px;
      font-family: "bold";
      color: #61439f;
    }
  }
  .misChart {
    background-color: #ffffff;
    padding: 6px;
    border-radius: 10px;
    .chartHead {
      display: flex;
      padding: 0px 15px;
      justify-content: space-between;
      align-items: center;
      .revenue {
        h4 {
          font-size: 16px;
          font-family: "bold";
          color: #202020;
        }
        h2 {
          font-size: 28px;
          font-family: "bold";
          color: #202020;
          line-height: 40px;
        }
      }
      .filter {
        .selectt {
          width: 20px;
        }
      }
    }
  }

  .orderBar {
    display: flex;
    padding: 20px 0px;
    justify-content: space-between;
    align-items: center;
    .btns {
      width: 36%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .active_tabs {
        background-color: #ffffff !important;
        h4 {
          color: #202020 !important;
        }
        box-shadow: 1px 1px 5px 4px #0000001a;
        padding: 10px 20px;
        border-radius: 3px;
        cursor: pointer;
      }
      .tabs {
        background-color: #dedede;
        padding: 10px 20px;
        border-radius: 3px;
        cursor: pointer;
        h4 {
          color: #ffffff;
        }
      }
      h4 {
        font-size: 16px;
        font-family: "bold";
        color: #202020;
        padding: 0 !important;
      }
    }
    .export {
      background-color: #61439f;
      padding: 8px 20px;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      span {
        color: #ffffff;
        font-size: 16px;
        font-family: "regular";
      }
      figure{
        width: 20px;
        height: 20px;
        margin-left: 10px;
        img{
            height: 100%;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .addprdct {
    .orderBar {
      display: block;
    }
    .export {
      width: 114px;
      margin-top: 20px;
    }
  }
}