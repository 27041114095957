.dashboard {
  h3 {
    font-family: "bold";
    color: #61439f;
    margin: 0px 10px;
    font-size: 22px;
  }
  .dashSec {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;

    ul {
      margin: 10px 10px 10px 0px;
      border: 1px solid #b4b4b4;
      border-radius: 5px;
      background-color: #ffffff;
      flex-basis: 18%;
      :nth-child(1) {
        font-size: 14px;
        color: #4e4e4e;
      }
      :nth-child(2) {
        font-size: 18px;
        font-family: "bold";
      }
      li {
        padding: 5px 10px;
      }
    }
  }

  .orderSec {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    ul {
      margin: 10px 10px 10px 0px;
      border: 1px solid #b4b4b4;
      border-radius: 5px;
      background-color: #ffffff;
      flex-basis: 18%;
      :nth-child(1) {
        font-size: 14px;
        color: #4e4e4e;
      }
      :nth-child(2) {
        font-size: 18px;
        font-family: "bold";
      }
      li {
        padding: 5px 10px;
      }
    }
  }

  .productsSec {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    div {
      width: 100%;
    }
    .mainDiv {
      flex-basis: 45%;
      h6 {
        font-family: "bold";
        font-size: 16px;
        color: #61439f;
        padding-top: 5px;
      }

      p {
        font-size: 14px;
        font-family: "regular";
        color: #4e4e4e;
      }
      padding: 8px;
      border: 1px solid #b4b4b4;
      border-radius: 5px;
      width: 50%;
      margin: 10px;
      line-height: 20px;
      background-color: #ffffff;

      .topDiv {
        display: flex;

        .imgSec {
          figure {
            width: 75px;
            height: 60px;
            padding-right: 10px;
          }
        }

        // .rytdata{

        // }
      }
      .bottomDiv {
        width: 90%;
      }
    }
  }
}

//roles and premission table

.rolesTable{
  border-radius: 15px !important;
  box-sizing: border-box;
  table{
    font-family: 'regular' !important;
    thead{
      tr{
        th{
          font-family: 'bold' !important;
          font-size: 14px !important;
          border-right: 1px solid #E6E6E6 !important;
          &:first-child{
            background: #EDEDED;
            font-family: 'bold' !important;
            font-size: 14px !important;
          }
        }
      
      }
    }
    tbody{
      tr{
        th{
          border-bottom: 1px solid transparent !important;
          font-size: 14px !important;
          padding: 8px 8px 8px 14px !important;
          border-right: 1px solid #E6E6E6 !important;
          // padding: 10px !important;
          &:first-child{
            background: #EDEDED;
            font-family: 'regular' !important;
            font-size: 14px !important;
            // padding: 10px !important;
          }
        }
       td{
        padding: 8px !important;
        border-bottom: 1px solid transparent !important;
        border-right: 1px solid #E6E6E6 !important;
        svg{
          color: #00931C !important;
          font-size: 20px !important;
        }
       }
      }
    }
  }
 
}

@media (max-width:991px) {
  .accMain {
    display: block;
    .accleft {
      width: 100%;
    }
    .accright {
      width: 100%;
      margin-top: 25px;
    }
    .dashboard {
      .dashSec {
        ul {
          flex-basis: inherit;
          width: 30%;
        }
      }
      .orderSec {
        ul {
          flex-basis: inherit;
          width: 30%;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .accMain {
    .dashboard {
      .dashSec {
        ul {
          flex-basis: inherit;
          width: 43%;
        }
      }
      .orderSec {
        ul {
          flex-basis: inherit;
          width: 43%;
        }
      }
    }
  }
}