//add Product scss

.addprdct {
  .producthead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3 {
      font-family: "bold";
      color: #61439f;
      font-size: 22px;
      padding-bottom: 20px;
    }
    .btndiv {
      display: flex;
      width: 40%;
      justify-content: flex-end;
      .btndiv {
        display: flex;
        width: 40%;
        justify-content: flex-end;
        .publish {
          width: 37% !important;
        }
        .cancel {
          width: 37%;
          margin-left: 20px;
          button {
            background: #ff3131 !important;
          }
        }
      }
    }
  }
  .bulk {
    width: 154px;
    background: white;
    box-shadow: 0px 2px 16px #00000014;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    justify-content: center;
    p {
      font-family: "regular";
      padding-right: 10px;
      color: #141414;
    }
  }
  h4 {
    font-size: 17px;
    font-family: "bold";
    padding-top: 20px;
    padding-bottom: 20px;
    color: #141414;
  }
  .flexdiv {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    label {
      position: relative;
      .uploadImg {
        input {
          width: 100%;
          height: 100%;
          background: red;
          position: absolute;
          z-index: 1;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    li {
      list-style: none;
      width: 250px;
      height: 190px;
      position: relative;
      border-radius: 10px;
      // border: 1px solid #818181;
      .uploaded {
        width: 100%;
        height: 100%;
        svg {
          position: absolute;
          cursor: pointer;
          right: 3px;
          top: 5px;
          color: red;
        }
        figure {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }
    }
    .uploadImg {
      .bussinessimg {
        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 20px;
          li {
            width: 240px;
            height: 200px;
            // margin-right: 20px;
            .upload {
              border-radius: 10px;
              background: #fff5f5;
              display: flex;
              border: 2px dashed #818181;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              cursor: pointer;
              figure {
                width: 70px;
              }
            }
            .uploaded {
              height: 100%;
              position: relative;
              svg {
                position: absolute;
                right: 0;
                cursor: pointer;
                z-index: 1;
              }
              figure {
                height: 100%;
                img {
                  height: 100%;
                  border-radius: 8px;
                }
              }
            }
          }
          .defaultimg {
            width: 47%;
            figure {
              margin: 0;
              width: 100%;
              height: 240px;
              img {
                width: 100%;
              }
            }
          }
          li {
            list-style: none;
            width: 250px;
            height: 190px;
          }
        }
      }
    }
  }
  form {
    .inputdivs {
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      justify-content: space-between;
      .inputcontainer {
        display: flex;
        flex-direction: column;
        width: 32%;
        label {
          color: #141414;
          font-size: 16px;
          font-family: "bold";
          padding-bottom: 10px;
          padding-top: 14px;
        }

        .MuiFormControl-root.MuiTextField-root,
        .MuiFormControl-root.MuiFormControl-fullWidth,
        .textfiled,
        .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.textfiled.css-wb57ya-MuiFormControl-root-MuiTextField-root {
          input {
            border: 0.5px solid #b4b4b4;
            border-radius: 5px;
            background: white;
            padding: 6px 5px !important;
          }
        }
      }
    }
    .sepecifaction {
      padding-top: 20px;
      label {
        color: #141414;
        font-size: 16px;
        font-family: "bold";
        padding-bottom: 10px;
        padding-top: 14px;
      }
      .textfiled {
        padding-top: 10px;
        div {
          padding: 0 !important;
          input,
          textarea {
            border: 0.5px solid #b4b4b4;
            border-radius: 5px;
            background: white;
            padding: 6px 5px !important;
          }
        }
      }
      .dragndrop {
        height: 100%;
        min-height: 150px;
        background: red;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px dashed #c6c6c6;
        border-radius: 10px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .pImg {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          & > div {
            width: 100%;
            height: 100%;
            cursor: pointer;
            label {
              height: 100%;
              width: 100%;
              cursor: pointer;
              .uploader {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;
                h4,
                p {
                  text-align: center;
                }
              }
            }
          }
          .drag-img {
            position: relative;
            width: 100%;
            height: 100%;
            svg {
              color: red;
              // position: absolute;
              right: 0;
              top: 0;
              cursor: pointer;
            }
            figure {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        h4 {
          font-size: "light";
          padding-top: 0 !important;
          padding-bottom: 10px;
          color: #141414;
        }
        p {
          color: #818181;
        }
      }
    }
  }
}

.tablemain {
  table {
    thead {
      tr {
        th {
          font-family: "bold" !important;
          font-size: 15px !important;
        }
      }
    }
    tbody {
      tr {
        th {
          font-family: "regular" !important;
          font-size: 14px !important;
        }
        td {
          font-family: "regular" !important;
          font-size: 14px !important;
          svg {
            // color: #61439f !important;
            // color: green !important;
            font-size: 22px !important;
            cursor: pointer;
            &:nth-child(2) {
              color: green !important;
              padding-left: 10px !important;
            }
          }
        }
      }
    }
  }
}
.mageuserslt {
  width: 158px !important;
  font-family: "regular" !important;
  margin-right: 10px;
}

.btnpublish {
  background: linear-gradient(rgb(96, 69, 160) 0%, rgb(71, 184, 233) 100%) 0% 0%
    no-repeat padding-box padding-box transparent;
  font-family: regular;
  font-weight: normal;
  font-size: 16px;
  text-transform: capitalize;
  width: 100%;
  padding: 8px;
  outline: none;
  border: none;
  color: white;
  padding: 12px 9px;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.cross_icn {
  color: red;
  right: 0;
  top: 0;
  cursor: pointer;
  position: absolute;
  z-index: 3;
}
