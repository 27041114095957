@import "../../assets/theme/Variable.scss";

.checkmain {
  padding-bottom: 40px;
     min-height: 800px;
  
  .chmain {
    .checkBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #dedede 0% 0% no-repeat padding-box;
      border-radius: 6px;
      .cbLeft {
        width: 50%;
        padding: 10px;
        h2 {
          font-size: 18px;
          font-family: "regular";
        }
      }
      .cbRyt {
        width: 46%;
        padding: 10px;
        h2 {
          font-size: 18px;
          font-family: "regular";
        }
      }
    }
    .checkdetail {
      display: flex;
      justify-content: space-between;
      .cDLeft {
        width: 52%;
        padding-top: 15px;
        height: 460px;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar{
          display: none;
        }
        padding-top: 15px;
        .checkaddress.profilemain {
          padding: 0 !important;
          h3 {
            font-size: 18px;
            padding-bottom: 17px;
            font-family: "regular";
          }
        }

        .chekcard {
          box-sizing: border-box;
          margin-bottom: 20px;
          padding: 10px;
          border: 0.5px solid #4e4e4e3b;
          border-radius: 5px;
          .address {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              font-size: 14px;
              color: #4e4e4e;
              font-family: 'regular';
            }
            h6 {
              font-size: 14px;
              font-family: "regular";
              color: $secondary;
              cursor: pointer;
            }
          }
          .cardDiv {
            display: flex;
            justify-content: space-between;
            position: relative;
            h5 {
              font-family: "bold";
              font-size: 19px;  
            }
            p {
              font-size: 17px;
              color: $black;
              padding-top: 5px;
              font-family: 'regular';
            }
            .radioicon {
              display: flex;
              justify-content: flex-end;
              position: absolute;
              right: -27px;
            }
          }
        }
        .addaddress {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;
          svg {
            border-radius: 100%;
            background: $secondary;
            color: white;
            margin-right: 8px;
          }
          h3 {
            font-size: 16px;
            font-family: "regular";
            margin-bottom: 0;
          }
        }
        .paycard {
          .pcard {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #b4b4b4;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 20px;
            .pcLeft {
              display: flex;
              align-items: center;
              width: 80%;
              figure {
                width: 60px;
                height: 20px;
                margin-right: 15px;
                border: 0.5px solid #b4b4b4;
                border-radius: 5px;
                padding: 10px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .detail {
              h4 {
                font-size: 14px;
                padding-bottom: 8px;
                font-family: "regular";
              }
              p {
                font-size: 14px;
                color: $grey;
                font-family: 'regular';
              }
            }
          }
          .pcRyt {
            width: 30%;
            display: flex;
            justify-content: flex-end;
            label {
              margin: 0 !important;
            }
          }
         
        }
      }
    }
    .cDRight {
      width: 47%;
      border-left: 1px solid #b4b4b4;
      padding-left: 10px;
      box-sizing: border-box;
      height: 100%;
      padding-top: 15px;
      .check_main {
        max-height: 340px;
        overflow: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar{
          display: none;
        }
    }
      .Pricedetail {
        padding-top: 20px;
      }
      .btn {
        padding-top: 10px;
      }

      .orders-data {
        display: flex;
        padding: 4px;
        border: 1px solid #dedede;
        margin-bottom: 15px !important;
        border-radius: 8px;
        position: relative;
        .order-img {
          width: 160px;
          padding: 4px;
          padding-right: 10px;
          height: 140px;
        }
        .product-details {
          font-size: 12px;
          margin-top: 6px;
          line-height: 20px;
          width: 70%;
          h4 {
            color: $secondary;
            font-size: 16px;
            padding-bottom: 5px;
            font-family: "bold";
          }
          p {
            color: $grey;
            font-size: 15px;
            padding-bottom: 6px;
            width: 80%;
            font-family: "regular";
          }
          span {
            color: $secondary;
            font-family: "bold";
            font-size: 14px;
          }
        }
      }
      h3 {
        font-size: 19px;
        margin-bottom: 13px;
        font-family: 'bold';
      }
      .pdleft {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        h4 {
          font-family: "light";
          font-size: 16px;
        }
      }
      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-family: 'extrabold';
          font-size: 18px;
        }
      }
    }
  }
}

.addcar.profilemain {
  padding: 0;
  h3 {
    font-size: 17px;
    font-family: "regular";
    padding: 0px 0 12px;
  }
}

//modal

.congmodal {
  figure {
    width: 130px;
    height: 130px;
    margin: auto;
  }
  h3 {
    font-size: 22px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
    font-family: 'bold';
  }
  p {
    font-size: 17px;
    text-align: center;
    color: #818181;
    padding-bottom: 15px;
    font-family: "regular";
    width: 70%;
    margin: auto;
  }
  .btn {
    width: 40%;
    margin: auto;
    button {
      text-transform: inherit !important;
    }
  }
}

.checoutproduct {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #4e4e4e3b;
  padding-top: 20px;
  &:nth-child(1) {
    padding-top: 0px;
  }
  .chleft {
    width: 30%;
    figure {
      width: 150px;
      height: 130px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .chrightt {
    width: 79%;
    h3 {
      font-size: 18px;
      margin-bottom: 13px;
      color: $black;
    }
    p {
      color: $grey;
      font-size: 14px;
      padding-bottom: 5px;
      line-height: 20px;
      width: 70%;
      font-family: 'regular';
    }
    h5 {
      font-size: 14px;
      font-family: "regular";
      color: $secondary;
    }
  }
}
@media (max-width: 1400px) {
  .checoutproduct {
      .chleft {
          width: 38%;
      }
      .chrightt {
          width: 68%;
      }
  }
}
@media (max-width: 1024px) {
  .checoutproduct {
      display: block;
      .chleft {
          width: 100%;
      }
      .chrightt {
          width: 100%;
      }
  }
}
@media (max-width: 992px) {
  .cartInfo {
      .table_rep {
          overflow-x: auto;
          width: 100%;
          tr {
              white-space: nowrap;
          }
      }
  }
}

@media (max-width: 576px) {
  .cartInfo {
      .addcart_main {
          .addCart {
              display: flex;
              .acRight2 {
                  width: 53%;
              }
          }
      }
  }
  .check_main {
      .checoutproduct {
          display: flex;
          justify-content: space-between;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(78, 78, 78, 0.231372549);
          .chleft {
              width: 40%;
          }
          .chrightt {
              width: 60%;
          }
      }
  }
  .checkmain {
    .chmain {
      .checkdetail {
        display: block;
        .cDLeft {
            width: 100%;
            padding-top: 0;
            height: inherit;
        }
        .cDRight {
            width: 100%;
            border-left: 0;
        }
    }
    }
  }
}
@media (max-width: 480px) {
  .check_main {
      .checoutproduct {
          display: block;
          .chleft {
              width: 100%;
          }
          .chrightt {
              width: 100%;
          }
      }
  }
  .cartInfo {
      .addcart_main {
          .addCart {
              display: block;
              .acLeft2 {
                  width: 100%;
                  justify-content: center;
                  margin-bottom: 15px;
              }
              .acRight2 {
                  width: 100%;
              }
          }
      }
  }
}