@import "./assets/theme/Variable.scss";
.container {
  margin: 0 auto;
  width: 90%;
  @media (max-width: 1200px) {
    width: 95%;
  }
}
.container2 {
  width: 80%;
  margin: 0 auto;
}

li {
  list-style: none;
}
ul {
  padding-left: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
ul {
  margin: 0;
}
img {
  width: 100%;
  height: 100%;
}
body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  background: $white;
  position: relative;
}

.container {
  width: 90%;
  margin: 0 auto;
}
.wrapcls {
  padding-top: 130px;
  min-height: calc(100vh - 557px);
}

.slider-box .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: gray;
  background-color: white;
  padding: 4px 4px 2px 4px;
  border-radius: 50%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-box {
  h2 {
    font-family: "bold" !important;
    font-size: 21px !important;
    margin: 20px 0;
  }
}

.slider-box .slick-prev:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: gray;
  background-color: white;
  padding: 4px 4px 2px 4px;
  border-radius: 50%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

fieldset {
  display: none;
}

@font-face {
  font-family: regular;
  src: url(./assets/fonts/ProximaNova-Regular.ttf);
}
@font-face {
  font-family: bold;
  src: url(./assets/fonts/ProximaNova-Bold.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(./assets/fonts/ProximaNova-Extrabld.ttf);
}
@font-face {
  font-family: light;
  src: url(./assets/fonts/ProximaNova-Light.ttf);
}

.adornment {
  div {
    padding-right: 0 !important;
  }
  .iconador {
    position: absolute !important;
    right: 10px !important;
    top: 40% !important;
  }
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-ad6o9x-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0 !important;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.productInfo {
  padding-bottom: 50px;
}

.boldhead {
  font-family: "bold";
}
.active {
  background: $secondary;
  color: white;
}

.mn_lyout {
  display: flex;
  flex-direction: column;
  // height: 100vh;
}

.textarea div {
  padding: 0 !important;
}

.pcard:nth-child(2) .pcLeft figure {
  width: 60px !important;
  padding: 6px !important;
  height: 30px !important;
}

.headingtext {
  background: $secondary !important;
  color: $white !important;
  font-size: 16px !important;
  svg {
    color: $white !important;
  }
}

.subtext {
  span {
    font-family: "regular" !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
  }
}
.subtext2 {
  span {
    font-family: "regular" !important;
    font-size: 14px !important;
    color: $secondary !important;
    text-transform: capitalize !important;
  }
}
.subcat {
  span {
    font-family: "light" !important;
    font-size: 16px !important;
    text-transform: capitalize;
  }
}

::placeholder {
  font-family: "regular" !important;
}
.bold1 {
  span {
    font-family: "regular" !important;
    font-size: 17px !important;
    color: #818181;
  }
}
.boldhead2 {
  span {
    font-family: "bold" !important;
    font-size: 17px !important;
  }
  .subcat {
    font-family: "regular" !important;
  }
}
.iconuser {
  min-width: 32px !important;
  padding-bottom: 3px !important;
}

.info_option {
  color: #818181 !important;
  &:hover {
    background: #f5f0ff !important;
    color: $secondary !important;
  }
}

.info_actv {
  background: #f5f0ff !important;
  color: $secondary !important;
}
.menu_item {
  font-family: "regular" !important;
}
button {
  font-family: "regular" !important;
}

.btnddiv {
  button {
    background: linear-gradient(rgb(96, 69, 160) 0%, rgb(71, 184, 233) 100%) 0%
      0% no-repeat padding-box padding-box transparent;
    font-family: "regular";
    font-size: 16px;
    text-transform: capitalize;
    width: 100%;
    padding: 10px 8px;
    color: white;
    font-size: 18px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
        rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    }
  }
}
//addproducts
.publish {
  width: 37% !important;
}
.cancel {
  width: 37%;
  margin-left: 20px;
  button {
    background: #ff3131 !important;
  }
}

.addselectbox {
  border: 0.5px solid #b4b4b4 !important;
  border-radius: 5px !important;
  background: white !important;
  padding: 6px 5px !important;
  font-family: "regular" !important;
  div {
    width: 100% !important;
    padding: 0 !important;
  }
  .selectadd {
    &::before {
      border-bottom: 1px solid transparent !important;
    }
    &::after {
      border-bottom: 2px solid transparent !important;
    }
  }
}

.cms_brcm {
  color: white;
}

.info_option {
  background: red;
}

.info_option {
  background: red;
}

.tablepanal {
  button {
    padding: 0 !important;
    min-width: 0px !important;
    margin-right: 15px !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-family: "regular";
  }
}

.srcv-lst {
  font-family: "regular" !important;
}
.srcv-lst {
  font-family: "regular" !important;
  div {
    font-family: "regular" !important;
  }
}
.selectt,
.mageuserslt {
  width: 114px !important;
  font-family: "regular" !important;
  & > div {
    padding: 5.5px 14px !important;
  }
}
.srvc-sel {
  div {
    padding: 10.5px 6px !important;
  }
}

.paydiv {
  color: "#4E4E4E";
  font-size: 16px;
  font-family: "bold";
}

.daterange {
  & > div {
    padding-top: 10px !important;
    label {
      top: -4px !important;
    }
  }
}
// .srvc-sel {
//   margin-top: 8px !important;
// }
.filtermain {
  .txt {
    padding-top: 10px !important;
  }
}

.MuiSwitch-switchBase.Mui-checked {
  color: green !important;
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background: #3dac52 !important;
}
.addrole {
  padding-bottom: 20px;
  display: flex;
  align-items: flex-start;
  input {
    border: 1px solid grey !important;
    border-radius: 5px !important;
    padding: 9px !important;
    width: 100%;
  }
  button {
    background: $secondary;
    outline: none;
    cursor: pointer;
    padding: 13px 21px;
    outline: none;
    border: none;
    margin-left: 10px;
    border-radius: 4px;
    color: white;
    font-size: 15px;
  }
}

.css-9ymwdr-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #2c106a;
  display: none !important;
}
.err_msg_sub {
  color: #ff0000bf !important;
  padding: 0px !important;
  text-align: left !important;
  font-size: 14px !important;
  font-family: "light";
  position: absolute;
}
.err_msg {
  color: #ff0000bf !important;
  padding: 0px !important;
  padding-bottom: 20px !important;
  text-align: left !important;
  font-size: 14px !important;
  font-family: "light";
}
.err_msg2 {
  color: #ff0000bf !important;
  padding: 0px !important;
  padding-bottom: 20px !important;
  text-align: center !important;
  font-size: 14px !important;
  font-family: "light";
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;
  figure {
    width: 70px;
    height: 70px;
  }
}

.react-tel-input .form-control {
  &:focus {
    border-color: "red" !important;
    box-shadow: none !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.fdiv h4 {
  margin-bottom: 2px;
  font-size: 15px;
  font-family: "regular";
  color: rgba(0, 0, 0, 0.8509803922);
}
.text_field p {
  color: red !important;
  padding: 0px !important;
  padding-bottom: 20px !important;
  margin: 0px !important;
  text-align: left !important;
  line-height: 18px !important;
  font-size: 14px !important;
}
.text_field2 {
  div {
    padding: 0 !important;
  }
  p {
    color: red !important;
    padding: 0px !important;
    padding-bottom: 20px !important;
    margin: 0px !important;
    text-align: left !important;
    line-height: 18px !important;
    font-size: 14px !important;
  }
}

.prevbtn {
  border: none;
  background-color: transparent;
}
.count {
  border: none;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.tableCount {
  margin-top: 0px !important;
}

.slick-slide.slick-active.slick-current,
.slick-slide.slick-active,
.slick-slide {
  height: 170px !important;
  & > div {
    height: 100% !important;
  }
}
// .slick-cloned{
//   display: none !important;
// }
.slick-track {
  margin: 0 !important;
}

.mange_usr {
  th:last-child {
    position: relative;
    display: revert !important;
  }
}
.tabledv {
  & > div {
    &::-webkit-scrollbar {
      height: 3px !important;
    }
  }
}

.mageuserslt input {
  border: none;
  opacity: 1;
  padding: 0 10px;
  height: 100%;
  font-size: 15px;
  text-align: center;
}

.mageuser_slt {
  border: none;
  opacity: 1;
  padding: 0 10px;
  height: 32px;
  width: 160px !important;
  text-align: center;
}

.warning-modal {
  h3 {
    font-size: 19px;
    font-family: "regular";
    margin-bottom: 20px;
    text-align: center;
  }
  .btn-div {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: auto;
    button.yes {
      width: 45%;
      border: none;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      background: $secondary;
      color: white;
      outline: none;
    }
    button.no {
      width: 45%;
      border: none;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      color: $secondary;
      background: white;
      outline: none;
      border: 1px solid $secondary;
    }
  }
}
.pCard .pData ul li:nth-child(4) {
  padding-bottom: 11px !important;
}
.orgin {
  padding-bottom: 11px !important;
}

@media (max-width: 480px) {
  .addrole {
    display: block;
    button {
      margin: 12px 0 0 6px;
    }
  }
}

.no_data {
  min-height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  figure {
    width: 90px;
    height: 90px;
    @media (max-width: 767px) {
      width: 70px;
      height: 70px;
  }
    img {
      height: 100%;
    }
  }
  h3 {
    font-size: 16px;
    font-family: "regular";
    color: rgb(128 128 128 / 88%);
  }
}

.react-pdf__Page__textContent.textLayer {
  display: none !important;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none !important;
}

.react-pdf__Document .react-pdf__Page {
  width: 100% !important;
  height: 400px !important;
  // overflow: auto;
}

.react-pdf__Document .react-pdf__Page canvas.react-pdf__Page__canvas {
  position: relative;
  cursor: pointer;
  margin: auto;
}

.react-pdf__Document {
  height: 100% !important;
  border: 1px solid black;
  overflow: scroll;
  overflow-x: hidden;
}

.react-pdf__Page__canvas {
  width: 100% !important;
}
// .certifi .react-pdf__Document .react-pdf__Page {
//   width: 100% !important;
//   height: 290px !important;
//   overflow: auto;
// }
@media (max-width: 480px) {
  .slider-box {
      h2 {
        font-size: 18px !important;
      }
  }
  .categriessec {
    .category {
      .cright {
        .pmain {
          .tilebar {
            h2 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'regular';
    font-size: 18px;
}
  p {
    font-size: 14px;
    font-family: "regular";
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 15px;
  }
    span {
      font-family: "bold";
    }
  }
  .pagdrp{
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    @media (max-width: 767px) {
      width: 100%;
  }
  div{
    position: relative;
    .dropse{
      width: 20px;
      position: absolute;
      right: 0;
      display: flex !important;
      justify-content: flex-end !important;
      top: -10px;
      z-index: 100;
    }
  }

  }
  .pages {
    display: flex;
    svg {
      font-size: 12px;
      border: 0.5px solid #a5a5a5;
      padding: 6px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      cursor: pointer;
    }
    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid #a5a5a5;
      .actv {
        background: $secondary;
        color: $white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }
      .inActv {
        background: transparent;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        border: none;
      }
    }
  }
}

.dropse{
  svg{
    padding-right: 10px !important;
    @media (max-width: 767px) {
      padding-right: 0px !important;
  }
  }
}
